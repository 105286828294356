import { useInfiniteQuery } from '@tanstack/react-query';

import { useCurrentUser } from 'hooks/selectors/useCurrentUser';
import { useGeolocation } from 'hooks/useGeolocation';
import { get } from 'util/httpsClient';
import { buildQueryString } from 'util/httpUtils';

export const useGetUserListings = ({ userId }: { userId: string }) => {
  const { userGeolocation } = useGeolocation();

  const currentUser = useCurrentUser();
  const isOwn = currentUser?.id?.uuid && userId && currentUser?.id.uuid === userId;

  return useInfiniteQuery({
    enabled: Boolean(userId),
    queryKey: ['userListings', { userId }],
    queryFn: async ({ pageParam = 1 }) => {
      const queryString = buildQueryString({
        country: userGeolocation,
        page: pageParam,
        userId,
      });
      const endpoint = isOwn ? 'own' : 'user';
      const path = `/listings/${endpoint}${queryString ? `?${queryString}` : ''}` as const;

      const response = await get({
        path,
      });

      return {
        listings: response.listings,
        currentPage: response.meta.page,
        totalPages: response.meta.totalPages,
      };
    },
    getNextPageParam: lastPage => {
      if (lastPage.currentPage < lastPage.totalPages) {
        return lastPage.currentPage + 1;
      }
      return undefined;
    },
  });
};
