import { denormalisedResponseEntities } from '../util/data';
import { storableError } from '../util/errors';
import * as log from '../util/log';
import { util as sdkUtil } from '../util/sdkLoader';
import { authInfo } from './auth.duck';
import { stripeAccountCreateSuccess } from './stripeConnectAccount.duck';

// ================ Action types ================ //

export const CURRENT_USER_SHOW_REQUEST = 'app/user/CURRENT_USER_SHOW_REQUEST';
export const CURRENT_USER_SHOW_SUCCESS = 'app/user/CURRENT_USER_SHOW_SUCCESS';
export const CURRENT_USER_SHOW_ERROR = 'app/user/CURRENT_USER_SHOW_ERROR';

export const CLEAR_CURRENT_USER = 'app/user/CLEAR_CURRENT_USER';

export const FETCH_CURRENT_USER_HAS_LISTINGS_REQUEST =
  'app/user/FETCH_CURRENT_USER_HAS_LISTINGS_REQUEST';
export const FETCH_CURRENT_USER_HAS_LISTINGS_SUCCESS =
  'app/user/FETCH_CURRENT_USER_HAS_LISTINGS_SUCCESS';
export const FETCH_CURRENT_USER_HAS_LISTINGS_ERROR =
  'app/user/FETCH_CURRENT_USER_HAS_LISTINGS_ERROR';

export const FETCH_CURRENT_USER_HAS_ORDERS_REQUEST =
  'app/user/FETCH_CURRENT_USER_HAS_ORDERS_REQUEST';
export const FETCH_CURRENT_USER_HAS_ORDERS_SUCCESS =
  'app/user/FETCH_CURRENT_USER_HAS_ORDERS_SUCCESS';
export const FETCH_CURRENT_USER_HAS_ORDERS_ERROR = 'app/user/FETCH_CURRENT_USER_HAS_ORDERS_ERROR';

export const SEND_VERIFICATION_EMAIL_REQUEST = 'app/user/SEND_VERIFICATION_EMAIL_REQUEST';
export const SEND_VERIFICATION_EMAIL_SUCCESS = 'app/user/SEND_VERIFICATION_EMAIL_SUCCESS';
export const SEND_VERIFICATION_EMAIL_ERROR = 'app/user/SEND_VERIFICATION_EMAIL_ERROR';

// ================ Reducer ================ //

const mergeCurrentUser = (oldCurrentUser, newCurrentUser) => {
  const { id: oId, type: oType, attributes: oAttr, ...oldRelationships } = oldCurrentUser || {};
  const { id, type, attributes, ...relationships } = newCurrentUser || {};

  // Passing null will remove currentUser entity.
  // Only relationships are merged.
  // TODO figure out if sparse fields handling needs a better handling.
  return newCurrentUser === null
    ? null
    : oldCurrentUser === null
    ? newCurrentUser
    : { id, type, attributes, ...oldRelationships, ...relationships };
};

const initialState = {
  currentUser: null,
  currentUserShowError: null,
};

export default function reducer(state = initialState, action = {}) {
  // @ts-expect-error TS(2339) FIXME: Property 'type' does not exist on type '{}'.
  const { type, payload } = action;
  switch (type) {
    case CURRENT_USER_SHOW_REQUEST:
      return { ...state, currentUserShowError: null };
    case CURRENT_USER_SHOW_SUCCESS:
      return { ...state, currentUser: mergeCurrentUser(state.currentUser, payload) };
    case CURRENT_USER_SHOW_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, currentUserShowError: payload };

    case CLEAR_CURRENT_USER:
      return {
        ...state,
        currentUser: null,
        currentUserShowError: null,
        currentUserNotificationCount: 0,
        currentUserNotificationCountError: null,
      };

    default:
      return state;
  }
}

// ================ Selectors ================ //

export const verificationSendingInProgress = state => {
  return state.user.sendVerificationEmailInProgress;
};

// ================ Action creators ================ //

export const currentUserShowRequest = () => ({ type: CURRENT_USER_SHOW_REQUEST });

export const currentUserShowSuccess = user => ({
  type: CURRENT_USER_SHOW_SUCCESS,
  payload: user,
});

export const currentUserShowError = e => ({
  type: CURRENT_USER_SHOW_ERROR,
  payload: e,
  error: true,
});

export const clearCurrentUser = () => ({ type: CLEAR_CURRENT_USER });

export const fetchCurrentUser =
  (params = null) =>
  (dispatch, getState, sdk) => {
    dispatch(currentUserShowRequest());
    const { isAuthenticated } = getState().auth;

    if (!isAuthenticated) {
      // Make sure current user is null
      dispatch(currentUserShowSuccess(null));
      return Promise.resolve({});
    }

    const parameters = params || {
      include: ['profileImage', 'stripeAccount'],
      'fields.image': [
        'variants.square-small',
        'variants.square-small2x',
        'variants.square-xsmall',
        'variants.square-xsmall2x',
      ],
      'imageVariant.square-xsmall': sdkUtil.objectQueryString({
        w: 40,
        h: 40,
        fit: 'crop',
      }),
      'imageVariant.square-xsmall2x': sdkUtil.objectQueryString({
        w: 80,
        h: 80,
        fit: 'crop',
      }),
    };

    return sdk.currentUser
      .show(parameters)
      .then(response => {
        const entities = denormalisedResponseEntities(response);
        if (entities.length !== 1) {
          throw new Error('Expected a resource in the sdk.currentUser.show response');
        }
        const currentUser = entities[0];

        // Save stripeAccount to store.stripe.stripeAccount if it exists
        if (currentUser.stripeAccount) {
          dispatch(stripeAccountCreateSuccess(currentUser.stripeAccount));
        }

        // set current user id to the logger
        log.setUserId(currentUser.id.uuid);
        dispatch(currentUserShowSuccess(currentUser));
        return currentUser;
      })
      .then(() => {
        // Make sure auth info is up to date
        dispatch(authInfo());
      })
      .catch(e => {
        // Make sure auth info is up to date
        dispatch(authInfo());
        // @ts-expect-error TS(2554) FIXME: Expected 3 arguments, but got 2.
        log.error(e, 'fetch-current-user-failed');
        dispatch(currentUserShowError(storableError(e)));
      });
  };
