import { useMutation } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import { fetchCurrentUser } from '../../../ducks/user.duck';
import { createUserWithIdp } from '../../../util/api';
import { get } from '../../../util/httpsClient';
import * as log from '../../../util/log';
import { authInfo } from 'ducks/auth.duck';
import { sharetribeSdkCookieStore } from 'index';
import { HandleExistsError } from 'util/customError';
import { formatDate } from 'util/dates';

export const useSignupWithIdp = () => {
  const dispatch = useDispatch();

  return useMutation({
    mutationFn: async (params: any) => {
      const urlParams = new URLSearchParams(window.location.search);
      const referralCode = urlParams.get('referralCode');
      params = { ...params, referralCode };
      params.dateOfBirth = formatDate(params.dateOfBirth);

      try {
        const existsRes = await get({
          path: `/users/exists?handle=${encodeURIComponent(params.handle)}`,
        });
        if (existsRes.userExists) {
          throw new HandleExistsError('User with this username already exists');
        }
        const res = await createUserWithIdp(params);
        sharetribeSdkCookieStore?.setToken(res.data);
        return dispatch(fetchCurrentUser());
      } catch (e) {
        log.error(e, 'create-user-with-idp-failed', { params });
        throw e;
      }
    },
    onSuccess: async () => {
      await dispatch(authInfo());
      await dispatch(fetchCurrentUser());
    },
    meta: { name: 'signupWithIdp' },
  });
};
