import * as Dialog from '@radix-ui/react-dialog';
import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useIntercom } from 'react-use-intercom';

import css from './TopbarMobileMenu.module.css';
import { IconClose, IconIntercomChat, IconMenu } from 'assets/icons';
import { Avatar, NamedLink } from 'components';
import { useHasIntercomCookie } from 'components/IntercomChatLauncher/IntercomChatLauncher';
import { ConditionType } from 'config/configListing';
import { InfoPageTab } from 'containers/InfoPage/InfoPage';
import { ProfileTab } from 'containers/ProfilePage/ProfilePage';
import { SettingsPageTab } from 'containers/SettingsPage/SettingsPage';
import { useCurrentUser } from 'hooks/selectors/useCurrentUser';
import { useGeolocation } from 'hooks/useGeolocation';
import { useMediaQueries } from 'hooks/useMediaQueries';
import { getCountryCodes, getFlagEmoji } from 'translations/countryCodes';
import { userDisplayNameAsString } from 'util/data';
import { DEFAULT_SORTING } from 'util/constants';

const TopbarMobileMenu = ({ isAuthenticated, onLogout }) => {
  const [open, setOpen] = useState(false);
  const isDesktop = useMediaQueries({ viewport: 'medium' });
  const currentUser = useCurrentUser();
  const displayName = userDisplayNameAsString(currentUser, 'USER')?.split(' ')[0];
  const { show: showIntercom } = useIntercom();
  const intl = useIntl();
  const { userGeolocation, updateGeolocation } = useGeolocation();
  const [countryCodes, setCountryCodes] = useState<{ code: string; name: any }[]>([]);

  useEffect(() => {
    const codes = getCountryCodes(intl.locale, true);
    setCountryCodes(codes);
  }, [intl.locale]);

  useEffect(() => {
    if (isDesktop) {
      setOpen(false);
    }
  }, [isDesktop]);

  const hasIntercomCookie = useHasIntercomCookie();
  const isOpen = open && !isDesktop;

  const handleCountryChange = event => {
    const newCountry = event.target.value;
    updateGeolocation(newCountry);
  };

  return (
    <Dialog.Root open={isOpen} onOpenChange={setOpen}>
      <Dialog.Trigger className={css.trigger}>
        <IconMenu />
      </Dialog.Trigger>
      <Dialog.Portal forceMount>
        <AnimatePresence>
          {isOpen && (
            <>
              <Dialog.Overlay asChild>
                <motion.div
                  key="overlay"
                  className={css.overlay}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.2 }}
                />
              </Dialog.Overlay>
              <Dialog.Content asChild>
                <motion.div
                  key="content"
                  className={css.content}
                  initial={{ x: '-100%' }}
                  animate={{ x: '0' }}
                  exit={{ x: '-100%' }}
                  transition={{ duration: 0.2 }}
                >
                  <Dialog.Close className={css.content__closeButton}>
                    <IconClose title="close" />
                  </Dialog.Close>
                  <div
                    className={classNames(css.header, {
                      [css['header--authenticated']]: isAuthenticated,
                    })}
                  >
                    {isAuthenticated && (
                      <Avatar className={css.header__avatar} user={currentUser} />
                    )}
                    <div>HEY, {isAuthenticated ? displayName : 'Nolder'}</div>
                    {isAuthenticated ? (
                      <button
                        onClick={() => {
                          onLogout();
                          setOpen(false);
                        }}
                        className="uppercase"
                      >
                        Log out
                      </button>
                    ) : (
                      <div className="uppercase">
                        <NamedLink
                          name="LoginPage"
                          onClick={() => setOpen(false)}
                          style={{ textDecoration: 'underline' }}
                        >
                          Log in
                        </NamedLink>
                        {' or '}
                        <NamedLink
                          name="SignupPage"
                          onClick={() => setOpen(false)}
                          style={{ textDecoration: 'underline' }}
                        >
                          Sign up
                        </NamedLink>
                      </div>
                    )}
                  </div>
                  <div className={css.section}>
                    <NamedLink name="ResellLandingPage" onClick={() => setOpen(false)}>
                      SELL
                    </NamedLink>
                    <NamedLink name="SearchPage" onClick={() => setOpen(false)}>
                      SHOP ALL
                    </NamedLink>
                    <NamedLink
                      name="SearchPage"
                      to={{
                        search: new URLSearchParams({
                          condition: ConditionType.SampleSale,
                          sortBy: DEFAULT_SORTING,
                        }).toString(),
                      }}
                      onClick={() => setOpen(false)}
                    >
                      SAMPLES
                    </NamedLink>
                    <NamedLink
                      name="SearchPage"
                      to={{ search: new URLSearchParams({ preloved: String(true) }).toString() }}
                      onClick={() => setOpen(false)}
                    >
                      PRE-LOVED
                    </NamedLink>
                  </div>
                  {isAuthenticated ? (
                    <div className={css.section} style={{ alignSelf: 'end' }}>
                      <div className={css.section__title}>Your profile</div>
                      <NamedLink
                        name="SettingsPage"
                        params={{ tab: SettingsPageTab.EditProfile }}
                        onClick={() => setOpen(false)}
                      >
                        Profile Settings
                      </NamedLink>
                      <NamedLink name="MyProfilePage" onClick={() => setOpen(false)}>
                        My Nold Profile
                      </NamedLink>
                      <NamedLink
                        name="MyProfilePage"
                        to={{ search: `profileTab=${ProfileTab.FAVOURITES}` }}
                        onClick={() => setOpen(false)}
                      >
                        Wishlist
                      </NamedLink>
                      <NamedLink name="WalletPage" onClick={() => setOpen(false)}>
                        Wallet
                      </NamedLink>
                      <NamedLink
                        name="MyProfilePage"
                        to={{ search: `profileTab=${ProfileTab.ORDERS}` }}
                        onClick={() => setOpen(false)}
                      >
                        My Orders
                      </NamedLink>
                      <NamedLink
                        name="InfoPage"
                        params={{ tab: InfoPageTab.HowItWorks }}
                        onClick={() => setOpen(false)}
                      >
                        How it works
                      </NamedLink>
                    </div>
                  ) : null}
                  <div className={css.section}>
                    <div className={css.selectWrapper}>
                      <select
                        id="mobile-country-select"
                        value={userGeolocation}
                        onChange={handleCountryChange}
                      >
                        {countryCodes.map(country => (
                          <option key={country.code} value={country.code}>
                            {getFlagEmoji(country.code)} {country.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    {hasIntercomCookie ? (
                      <button onClick={showIntercom} className="flex gap-1">
                        <IconIntercomChat /> Chat with us
                      </button>
                    ) : (
                      <a href="mailto:hello@thenold.com" className="flex gap-1">
                        <IconIntercomChat /> Chat with us
                      </a>
                    )}
                  </div>
                </motion.div>
              </Dialog.Content>
            </>
          )}
        </AnimatePresence>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default TopbarMobileMenu;
