import React, { PropsWithChildren, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHover } from 'usehooks-ts';

import { useConfiguration } from '../../../../context/configurationContext';
import css from './AddressItem.module.css';
import { ReactComponent as DeleteIcon } from './images/delete.svg';
import { ReactComponent as EditIcon } from './images/edit.svg';
import { Button, PrimaryButton } from 'components';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from 'components/_chadcn/Dialog';
import { useCountryCodes } from 'hooks/useCountryCodes';

const AddressItem = props => {
  const { selected, value, onEditClick, onDeleteClick, onClick } = props;

  const [isDeleting, setIsDeleting] = useState(false);
  const config = useConfiguration();
  const countryOptions = useCountryCodes().map(c => ({
    option: c.code,
    label: c.name,
  }));

  const ref = React.useRef(null);
  const isHovering = useHover(ref);
  const showButtons = Boolean((selected || isHovering) && (onEditClick || onDeleteClick));

  return (
    <CardButton
      selected={selected}
      onClick={() => {
        onClick?.(value);
      }}
      ref={ref}
    >
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'auto auto',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <div className={css.addressContainer}>
          <div>
            <p className={css.addressMain}>
              <span>{[value?.street1, value?.street2].filter(Boolean).join(', ')}</span>
              {value?.isDefault && (
                <span className={css.default}>
                  <FormattedMessage id="ProfileAddressesForm.default" />
                </span>
              )}
            </p>
          </div>

          <p className={css.address}>
            {[
              value?.state,
              value?.city,
              countryOptions.find(c => c.option === value?.country)?.label,
            ]
              .filter(Boolean)
              .join(', ')}
          </p>
        </div>
        <div className={css.actionIcons} data-show={showButtons}>
          {onEditClick && (
            <EditIcon
              onClick={e => {
                e.stopPropagation();
                onEditClick();
              }}
            />
          )}
          {onDeleteClick && (
            <Dialog open={isDeleting} onOpenChange={setIsDeleting}>
              <DialogTrigger asChild>
                <DeleteIcon
                  onClick={e => {
                    e.stopPropagation();
                    setIsDeleting(true);
                  }}
                />
              </DialogTrigger>
              <DialogContent>
                <DialogHeader>
                  <DialogTitle>Delete Address</DialogTitle>
                </DialogHeader>
                <DialogDescription>Are you sure you want to delete this address?</DialogDescription>
                <DialogFooter className="sm:justify-between">
                  <DialogClose asChild>
                    <Button>Cancel</Button>
                  </DialogClose>
                  <PrimaryButton
                    onClick={() => {
                      onDeleteClick();
                      setIsDeleting(false);
                    }}
                  >
                    Delete
                  </PrimaryButton>
                </DialogFooter>
              </DialogContent>
            </Dialog>
          )}
        </div>
      </div>
    </CardButton>
  );
};

type CardButtonProps = PropsWithChildren<{
  selected?: boolean;
  onClick?: () => void;
}>;

// TODO: Extract to components
export const CardButton = React.forwardRef<HTMLDivElement, CardButtonProps>((props, ref) => {
  const { selected, onClick } = props;
  const { children } = props;

  return (
    <div
      className={css.root}
      onClick={() => onClick?.()}
      ref={ref}
      data-selected={Boolean(selected)}
    >
      {children}
      {/* <div className={selected ? css.itemChecked : css.item}>{children}</div> */}
    </div>
  );
});

export default AddressItem;
