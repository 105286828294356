import { useConfiguration } from '../../../context/configurationContext';
import ProfileSettingsForm from './ProfileSettingsForm/ProfileSettingsForm';
import { useUpdateCurrentUser } from 'hooks/api/users';
import { useCurrentUser } from 'hooks/selectors/useCurrentUser';

export const EditProfile = props => {
  const config = useConfiguration();
  const currentUser = useCurrentUser();

  const {
    mutateAsync: updateUser,
    isLoading: isUpdating,
    error: updateUserError,
    isSuccess,
  } = useUpdateCurrentUser();

  const { firstName, lastName, bio, publicData } = currentUser.attributes.profile;
  const initialValues = {
    firstName,
    lastName,
    bio,
    profileImage: currentUser.profileImage,
    handle: publicData?.handle,
  };

  const handleSubmit = values => {
    const { firstName, lastName, bio: rawBio, handle } = values;

    // Ensure that the optional bio is a string
    const bio = rawBio || '';

    const profile = {
      firstName: firstName.trim(),
      lastName: lastName.trim(),
      bio,
      publicData: {
        handle,
      },
    };
    const uploadedImage = values.profileImage;

    // Update profileImage only if file system has been accessed
    const updatedValues =
      uploadedImage?.id?.uuid !== currentUser.profileImage?.id?.uuid
        ? {
            ...profile,
            profileImageId: uploadedImage.id,
            profileImageUrl: uploadedImage.attributes.variants['square-small'].url,
          }
        : profile;

    updateUser(updatedValues);
  };

  if (!currentUser.id?.uuid) {
    return null;
  }

  return (
    <ProfileSettingsForm
      currentUser={currentUser}
      initialValues={initialValues}
      updateInProgress={isUpdating}
      updateProfileError={updateUserError}
      onSubmit={handleSubmit}
      isSuccess={isSuccess}
      marketplaceName={config.marketplaceName}
      rootClassName={undefined}
      className={undefined}
      updateProfileReady={undefined}
    />
  );
};

export default EditProfile;
