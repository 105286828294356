import React, { useState } from 'react';

import { IconSpinner } from '../../../components';
import {
  useCreateAddress,
  useDeleteAddress,
  useGetAddresses,
  useUpdateAddress,
} from '../../../hooks/api/addresses';
import { FormattedMessage } from '../../../util/reactIntl';
import { AddressFormData, emptyAddress } from './components/AddressForm/AddressForm';
import AddressItem from './components/AddressItem';
import AddressModal from './components/AddressModal/AddressModal';
import AssociatedListingsModal from './components/AddressModal/AssociatedListingsModal';
import css from './ProfileAddressesForm.module.css';
import AddressesEmptyState from 'containers/EditListingPage/EditListingWizard/EditListingShipping/AddressesEmptyState';

type P = {
  rootClassName?: string;
  className?: string;
  pickedAddress?: string;
  onChangePickedAddress?: (id: string | undefined) => void;
};

const ProfileAddressesForm: React.FC<P> = props => {
  const { pickedAddress, onChangePickedAddress = v => {} } = props;

  const [showAssociatedListingsModal, setShowAssociatedListingsModal] = useState(false);

  const { data: addresses = [], isLoading: isGetting } = useGetAddresses();
  const { mutate: updateAddress, isLoading: isUpdating } = useUpdateAddress();
  const { mutate: addAddress, isLoading: isCreating } = useCreateAddress();
  const {
    mutate: deleteAddress,
    isLoading: isDeleting,
    error: deleteAddressError,
  } = useDeleteAddress();

  const isLoading = isGetting || isUpdating || isCreating || isDeleting;

  // TODO: Handle api errors
  const errors = [];

  const [showModal, setShowModal] = useState(false);
  const [currentAddress, setCurrentAddress] = useState<AddressFormData | undefined>();

  const openCreateAddressModal = () => {
    setCurrentAddress(emptyAddress);
    setShowModal(true);
  };

  const openAddressForEditing = value => {
    setCurrentAddress({
      ...value,
      validate: true,
    });

    setShowModal(true);
  };

  const upsertAddress = address => {
    const onSuccess = () => {
      setShowModal(false);
    };

    if (address.id) {
      updateAddress(address, { onSuccess });
    } else {
      addAddress(address, { onSuccess });
    }
  };

  const associatedListings = deleteAddressError?.data?.errors[0]?.metadata || [];

  return (
    <div className={css.root}>
      {Boolean(addresses.length) && isLoading && (
        <div className={css.titleContainer} style={{ display: 'grid', placeContent: 'center' }}>
          <IconSpinner />
        </div>
      )}
      <div className={css.addressesContainer}>
        {addresses?.length === 0 && !isGetting ? (
          <AddressesEmptyState onCreateAddressClick={openCreateAddressModal} />
        ) : null}
        {addresses?.map(value => (
          <AddressItem
            value={value}
            selected={pickedAddress === value.shippingId}
            onClick={() => onChangePickedAddress(value.shippingId)}
            onDeleteClick={() => {
              deleteAddress(value, {
                onError: error => {
                  if (error.data?.errors[0]?.type === 'AssociatedListingsAddressError') {
                    setShowAssociatedListingsModal(true);
                  }
                },
              });
              if (pickedAddress === value.shippingId) {
                onChangePickedAddress(undefined);
              }
            }}
            onEditClick={() => openAddressForEditing(value)}
          />
        ))}
        <AddressModal
          initialValues={currentAddress}
          open={showModal}
          showEuOptions={true}
          onOpenChange={v => {
            setShowModal(v);
            if (!v) {
              setCurrentAddress(undefined);
            }
          }}
          onSubmitAddress={upsertAddress}
          isSubmitting={isLoading}
        />
        <AssociatedListingsModal
          open={showAssociatedListingsModal}
          onClose={() => setShowAssociatedListingsModal(false)}
          listings={associatedListings}
        />
      </div>

      {Boolean(errors?.length) && (
        <div style={{ color: 'var(--colorFail)' }}>
          {errors.map(errors => (
            <p>{errors}</p>
          ))}
        </div>
      )}

      {addresses.length > 0 && (
        <div>
          <div className={css.addAddressButton} onClick={openCreateAddressModal}>
            <FormattedMessage id="ProfileAddressesForm.addAnotherAddress" />
          </div>
        </div>
      )}
    </div>
  );
};

export default ProfileAddressesForm;
