import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { useCurrentUser } from 'hooks/selectors/useCurrentUser';
import { APIError, post } from 'util/httpsClient';

export interface InitiateOrderRequest {
  rateId: string;
  shippingToAddressId: number;
  shippingToCountry?: string;
  billingAddressId: number | null;
  userGeolocation: string;
  listingId?: string;
  shippingMethodId?: string;
  discountCodes?: string[];
  useNoldCredit: boolean;
  isSpeculative: boolean;
  authenticate: boolean;
  isExpressCheckout: boolean;
  temporaryRateId?: string;
}
export const useInitiateOrder = () => {
  return useMutation({
    mutationFn: (request: InitiateOrderRequest) => {
      if (!request.shippingToAddressId && !request.shippingToCountry) {
        throw new Error('shippingToAddressId or shippingToCountry required');
      }
      return post({
        path: '/orders/initiate',
        body: request,
      });
    },
    onError: (error: any, variables: InitiateOrderRequest) => {
      console.error(error, 'initiate-order-failed', { variables });
      throw error;
    },
    retry: false,
    meta: { name: 'initiateOrder' },
  });
};

export function useGetSpeculativeTransaction(request: InitiateOrderRequest) {
  const currentUser = useCurrentUser();

  const { mutateAsync: initiateOrder } = useInitiateOrder();
  request.isSpeculative = true;

  const queryKey = ['speculative-transaction', { request }];

  const isEnabled =
    Boolean(currentUser.id?.uuid) && (request.isExpressCheckout || Boolean(request.rateId));

  return useQuery<any, APIError>({
    enabled: isEnabled,
    queryKey,
    queryFn: () => initiateOrder(request),
    retry: false,
  });
}
