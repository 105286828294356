import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useCallback, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { pathByRouteName } from '../../util/routes';
import { CheckoutFormData } from './CheckoutPage';
import { useRouteConfiguration } from 'context/routeConfigurationContext';
import { useCreateAddress, useGetAddresses } from 'hooks/api/addresses';
import { useGetListing } from 'hooks/api/listings/useGetListing';
import { useInitiateOrder } from 'hooks/api/transactions/useInitiateOrder';
import { useCurrentUser } from 'hooks/selectors/useCurrentUser';
import { ensureListing, ensureUser } from 'util/data';
import { get, post, put } from 'util/httpsClient';
import { useGeolocation } from 'hooks/useGeolocation';

export const useCreatePaymentMethod = () => {
  const stripe = useStripe();
  const elements = useElements();

  return useMutation({
    mutationFn: async () => {
      const { error, paymentMethod } =
        (await stripe?.createPaymentMethod({
          type: 'card',
          card: elements?.getElement(CardElement) as any,
        })) || {};

      if (error) {
        console.error('error creating payment method: ', error);
        throw error;
      }
      if (!paymentMethod) {
        throw new Error('no payment method');
      }

      return paymentMethod;
    },
    meta: { name: 'createPaymentMethod' },
  });
};

export const usePayItem = () => {
  const { mutateAsync: initiateOrder } = useInitiateOrder();
  const stripe = useStripe();
  const { userGeolocation } = useGeolocation();

  return useMutation({
    mutationFn: async ({
      listingId,
      orderData,
      paymentMethodId,
    }: {
      listingId: string;
      orderData: {
        shippingAddressId: string;
        billingAddressId: string;
        selectedRateId: string;
        useNoldCredit: boolean;
        authenticate: boolean;
        discountCodes: string[];
      };
      amountToPay: number;
      paymentMethodId: string;
      transactionId: string | null;
    }) => {
      const response = await initiateOrder({
        isSpeculative: false,
        listingId,
        shippingToAddressId: Number(orderData.shippingAddressId),
        userGeolocation: userGeolocation || 'GB',
        billingAddressId: orderData.billingAddressId ? Number(orderData.billingAddressId) : null,
        rateId: orderData.selectedRateId,
        useNoldCredit: orderData.useNoldCredit,
        authenticate: orderData.authenticate,
        discountCodes: orderData.discountCodes,
        isExpressCheckout: false,
      });

      const paymentIntent = await post({
        path: '/payment-intents',
        body: {
          paymentMethodId: paymentMethodId,
          transactionId: response.id.uuid || response.id,
        },
      });

      if (paymentIntent.status === 'requires_action') {
        const stripeResponse = await stripe?.confirmCardPayment(paymentIntent.client_secret);
        if (stripeResponse?.error) {
          throw new Error(stripeResponse.error.message);
        }
      }

      return { paymentIntentId: paymentIntent.id, transactionResponseId: response.id };
    },
    meta: { name: 'payItem' },
  });
};

export const useCreateStripeAccountIfNotExistent = shouldRedirect => {
  const currentUser = useCurrentUser();
  const {
    mutate: createStripeAccount,
    isLoading: isCreatingStripeAccount,
    error: stripeAccountCreateError,
  } = useMutation({
    mutationFn: async () => {
      return post({ path: '/users/stripe', body: {} });
    },
    meta: { name: 'createStripeAccountIfNotExistent' },
  });

  useEffect(() => {
    if (
      !shouldRedirect &&
      currentUser?.attributes.profile.privateData &&
      !currentUser?.attributes.profile.privateData?.stripeId
    ) {
      createStripeAccount();
    }
  }, [
    createStripeAccount,
    currentUser?.attributes.profile.privateData,
    currentUser?.attributes.profile.privateData?.stripeId,
    shouldRedirect,
  ]);

  return { isCreatingStripeAccount, stripeAccountCreateError };
};

export const useConfirmPayment = () => {
  return useMutation({
    mutationFn: async ({
      transactionId,
      paymentIntentId,
    }: {
      transactionId: string;
      paymentIntentId: string;
    }) => {
      return await put({
        path: '/payment-intents',
        body: {
          paymentIntentId,
          transactionId,
        },
      });
    },
    meta: { name: 'confirmPayment' },
  });
};

export function useRedirectToOrdersPage() {
  const currentUser = useCurrentUser();
  const routeConfiguration = useRouteConfiguration();

  // TODO: Type safe search params
  const successRedirect =
    pathByRouteName('ProfilePage', routeConfiguration, {
      id: currentUser?.id?.uuid || '0',
    }) + '?profileTab=orders';
  const history = useHistory();

  return useCallback(() => {
    history.push(successRedirect);
  }, [history, successRedirect]);
}

export function useShouldRedirect() {
  const { id: listingId } = useParams<{ id: string }>();
  const currentUser = useCurrentUser();
  const { data: listingData, isLoading } = useGetListing(listingId);

  const currentAuthor = ensureUser(ensureListing(listingData).author);
  const isOwnListing = currentUser?.id && currentAuthor?.id?.uuid === currentUser?.id?.uuid;
  const hasDateOfBirth = currentUser?.attributes.profile.privateData?.dateOfBirth;
  const hasRequiredData = Boolean(listingId && currentAuthor.id);
  const canShowPage = hasRequiredData && !isOwnListing;
  const shouldRedirect = !isLoading && (!hasDateOfBirth || !canShowPage);

  return shouldRedirect;
}

export function useSubmitPaymentRequest({ amountToPay, listingId, transactionId, orderData }) {
  const { mutateAsync: createPaymentMethod } = useCreatePaymentMethod();
  const { mutateAsync: payItem } = usePayItem();
  const { mutateAsync: confirmPayment } = useConfirmPayment();

  const redirectToOrdersPage = useRedirectToOrdersPage();

  return useMutation({
    mutationFn: async (values: CheckoutFormData) => {
      const paymentMethod = await createPaymentMethod();

      // TODO: If payment fails then we need to delete the address we just created
      const { paymentIntentId, transactionResponseId } = await payItem({
        orderData: {
          shippingAddressId: values.shippingAddressId || '',
          billingAddressId: values.billingAddressId || values.shippingAddressId || '',
          selectedRateId: values.shippingRateId,
          useNoldCredit: orderData.useNoldCredit,
          authenticate: orderData.authenticate,
          discountCodes: orderData.discountCodes,
        },
        listingId,
        amountToPay,
        paymentMethodId: paymentMethod.id,
        transactionId: transactionId || null,
      });

      await confirmPayment({ transactionId: transactionResponseId, paymentIntentId });

      redirectToOrdersPage();
    },
    meta: { name: 'submitPaymentRequest' },
  });
}

type GetShippingRatesParams = {
  buyerAddressId?: string;
  listingId: string;
  userGeolocation: string;
  address?: {
    zip: string;
    country: string;
    city: string;
  };
};

export function useGetShippingRates(params: GetShippingRatesParams) {
  const enabled = Boolean(params.listingId && (params.buyerAddressId || params.address));
  return useQuery({
    enabled,
    queryKey: getShippingRatesQueryKey(params),
    staleTime: 60 * 1000,
    cacheTime: 60 * 1000,
    queryFn: enabled ? getShippingRatesQueryFn(params) : undefined,
    meta: {
      errorMessage: 'Failed to fetch shipping rates',
    },
  });
}

export function getShippingRatesQueryKey(params: GetShippingRatesParams) {
  return ['shippingRates', params];
}

export function getShippingRatesQueryFn(params: GetShippingRatesParams) {
  if (!params.buyerAddressId && !params.address) {
    throw new Error('Missing address or buyerAddressId');
  }

  return async () =>
    get({
      path: `/orders/rates?${new URLSearchParams({
        listingId: params.listingId,
        ...(params.buyerAddressId && { buyerAddressId: params.buyerAddressId }),
        ...params.address,
        location: params.userGeolocation,
      }).toString()}`,
    });
}
