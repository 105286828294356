import classNames from 'classnames';
import React from 'react';
import { Field } from 'react-final-form';

import { ExpandingTextarea, ValidationError } from '../../components';
import css from './FieldTextInput.module.css';

const CONTENT_MAX_LENGTH = 5000;

type TextInputProps = {
  rootClassName?: string;
  className?: string;
  inputRootClass?: string;

  onUnmount?: () => void;

  // Error message that can be manually passed to input field,
  // overrides default validation message
  customErrorText?: string;

  // Label is optional, but if it is given, an id is also required so
  // the label can reference the input in the `for` attribute
  id?: string;
  label?: string;

  // Uncontrolled input uses defaultValue prop, but doesn't pass value from form to the field.
  // https://reactjs.org/docs/uncontrolled-components.html#default-values
  isUncontrolled?: boolean;
  // a ref object passed for input element.
  inputRef?: React.RefObject<HTMLInputElement>;
  hideErrorMessage?: boolean;

  // Generated by final-form's Field component
  input: {
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    // Either 'textarea' or something that is passed to the input element
    type: string;
    value: string;
  };
  meta: any;
};

export const TextInput: React.FC<TextInputProps> = props => {
  const {
    rootClassName,
    className,
    inputRootClass,
    customErrorText,
    id,
    label,
    input,
    meta,
    onUnmount,
    isUncontrolled = false,
    inputRef,
    hideErrorMessage,
    ...rest
  } = props;

  if (label && !id) {
    throw new Error('id required when a label is given');
  }

  const { invalid, touched, error } = meta;
  const isTextarea = input.type === 'textarea';

  const errorText = customErrorText || error;

  // Error message and input error styles are only shown if the
  // field has been touched and the validation has failed.
  const hasError = !!customErrorText || !!(touched && invalid && error);

  const fieldMeta = { touched: hasError, error: errorText };

  // Textarea doesn't need type.
  const { type, ...inputWithoutType } = input;
  // Uncontrolled input uses defaultValue instead of value.
  const { value: defaultValue, ...inputWithoutValue } = input;
  // Use inputRef if it is passed as prop.
  const refMaybe = inputRef ? { ref: inputRef } : {};

  const inputClasses = inputRootClass || classNames({ [css.inputError]: hasError });
  const maxLength = CONTENT_MAX_LENGTH;
  const inputProps = isTextarea
    ? {
        className: inputClasses,
        id,
        rows: 1,
        maxLength,
        ...refMaybe,
        ...inputWithoutType,
        ...rest,
      }
    : isUncontrolled
    ? {
        className: inputClasses,
        id,
        defaultValue,
        ...refMaybe,
        ...inputWithoutValue,
        ...rest,
      }
    : { className: inputClasses, id, ...refMaybe, ...input, ...rest };

  const classes = classNames(rootClassName || css.root, className);
  return (
    <div className={classes}>
      {label ? <label htmlFor={id}>{label}</label> : null}
      {isTextarea ? <ExpandingTextarea {...(inputProps as any)} /> : <input {...inputProps} />}
      {hideErrorMessage ? null : <ValidationError fieldMeta={fieldMeta} />}
    </div>
  );
};

const FieldTextInput = props => {
  return <Field component={TextInput} {...props} />;
};

export default FieldTextInput;
