import { Modal } from '../../../../../components';
import AddressForm from '../AddressForm/AddressForm';

type P = {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onSubmitAddress: (values: any) => void;
  showEuOptions: boolean;
  formOperation?: any;
  initialValues?: any;
  isSubmitting: boolean;
};

const AddressModal: React.FC<P> = props => {
  const {
    open,
    onOpenChange,
    onSubmitAddress,
    showEuOptions,
    formOperation,
    initialValues = {},
    isSubmitting,
  } = props;

  return (
    <Modal
      open={open}
      onOpenChange={onOpenChange}
      title={initialValues.id ? 'Edit address' : 'Create an address'}
      closeOnOutsideClick={false}
      useDivAsOverlay
    >
      <AddressForm
        initialValues={initialValues}
        onSubmit={onSubmitAddress}
        showEuOptions={showEuOptions}
        formOperation={formOperation}
        onCloseModal={onOpenChange}
        isSubmitting={isSubmitting}
      />
    </Modal>
  );
};

export default AddressModal;
