/**
 * Transaction process graph for product orders:
 *   - default-purchase
 */

/**
 * Transitions
 *
 * These strings must sync with values defined in Marketplace API,
 * since transaction objects given by API contain info about last transitions.
 * All the actions in API side happen in transitions,
 * so we need to understand what those strings mean.
 */

export const transitions = {
  INQUIRE: 'transition/inquire',
  REQUEST_TO_BUY_AFTER_INQUIRY: 'transition/request-to-buy-after-inquiry',
  REQUEST_TO_BUY: 'transition/request-to-buy',
  EDIT_LINE_ITEMS: 'transition/edit-line-items',
  PAY: 'transition/pay',
  AUTO_REJECT: 'transition/auto-reject',
  REJECT: 'transition/reject',
  ACCEPT: 'transition/accept',
  FAIL_TO_SEND: 'transition/fail-to-send',
  SEND: 'transition/send',
  SENT_TO_LOST: 'transition/sent-to-lost',
  REFUND_LOST: 'transition/refund-lost',
  DELIVER: 'transition/deliver',
  PICK_UP: 'transition/pick-up',
  FAIL_TO_PICK_UP: 'transition/fail-to-pick-up',
  DISPUTE: 'transition/dispute',
  COMPLETE: 'transition/complete',
  SELLER_WINS_DISPUTE: 'transition/seller-wins-dispute',
  BUYER_WINS_DISPUTE: 'transition/buyer-wins-dispute',
  SEND_BACK: 'transition/send-back',
  AWAITING_RETURN_TO_LOST: 'transition/awaiting-return-to-lost',
  REVIEW_1_BY_PROVIDER: 'transition/review-1-by-provider',
  REVIEW_2_BY_PROVIDER: 'transition/review-2-by-provider',
  REVIEW_1_BY_CUSTOMER: 'transition/review-1-by-customer',
  REVIEW_2_BY_CUSTOMER: 'transition/review-2-by-customer',
  EXPIRE_REVIEW_PERIOD: 'transition/expire-review-period',
  EXPIRE_PROVIDER_REVIEW_PERIOD: 'transition/expire-provider-review-period',
  EXPIRE_CUSTOMER_REVIEW_PERIOD: 'transition/expire-customer-review-period',
} as const;

/**
 * States
 *
 * These constants are only for making it clear how transitions work together.
 * You should not use these constants outside of this file.
 *
 * Note: these states are not in sync with states used transaction process definitions
 *       in Marketplace API. Only last transitions are passed along transaction object.
 */

const states = {
  INITIAL: 'initial',
  INQUIRY: 'state/inquiry',
  RESERVED: 'state/reserved',
  PAID: 'state/paid',
  CANCELED: 'state/canceled',
  ACCEPTED: 'state/accepted',
  SENT: 'state/sent',
  LOST: 'state/lost',
  LOST_REFUNDED: 'state/lost_refunded',
  DELIVERED: 'state/delivered',
  RETURNED: 'state/returned',
  DISPUTED: 'state/disputed',
  COMPLETED: 'state/completed',
  AWAITING_RETURN: 'state/awaiting_return',
  REVIEWED: 'state/reviewed',
  REVIEWED_BY_CUSTOMER: 'state/reviewed-by-customer',
  REVIEWED_BY_PROVIDER: 'state/reviewed-by-provider',
};

/**
 * Description of transaction process graph
 *
 * You should keep this in sync with transaction process defined in Marketplace API
 *
 * Note: we don't use yet any state machine library,
 *       but this description format is following Xstate (FSM library)
 *       https://xstate.js.org/docs/
 */

export const graph = {
  id: 'default-purchase/release-1',

  initial: states.INITIAL,

  states: {
    [states.INITIAL]: {
      on: {
        [transitions.INQUIRE]: states.INQUIRY,
        [transitions.REQUEST_TO_BUY]: states.RESERVED,
      },
    },
    [states.INQUIRY]: {
      on: {
        [transitions.REQUEST_TO_BUY_AFTER_INQUIRY]: states.RESERVED,
      },
    },
    [states.RESERVED]: {
      on: {
        [transitions.PAY]: states.PAID,
        [transitions.EDIT_LINE_ITEMS]: states.RESERVED,
      },
    },
    [states.PAID]: {
      on: {
        [transitions.AUTO_REJECT]: states.CANCELED,
        [transitions.REJECT]: states.CANCELED,
        [transitions.ACCEPT]: states.ACCEPTED,
      },
    },
    [states.ACCEPTED]: {
      on: {
        [transitions.FAIL_TO_SEND]: states.CANCELED,
        [transitions.SEND]: states.SENT,
      },
    },
    [states.SENT]: {
      on: {
        [transitions.SENT_TO_LOST]: states.LOST,
        [transitions.DELIVER]: states.DELIVERED,
      },
    },
    [states.LOST]: {
      on: {
        [transitions.REFUND_LOST]: states.LOST_REFUNDED,
      },
    },
    [states.LOST_REFUNDED]: {},
    [states.DELIVERED]: {
      on: {
        [transitions.DISPUTE]: states.DISPUTED,
        [transitions.COMPLETE]: states.COMPLETED,
        [transitions.FAIL_TO_PICK_UP]: states.RETURNED,
      },
    },
    [states.RETURNED]: {},
    [states.DISPUTED]: {
      on: {
        [transitions.SELLER_WINS_DISPUTE]: states.COMPLETED,
        [transitions.BUYER_WINS_DISPUTE]: states.AWAITING_RETURN,
      },
    },
    [states.AWAITING_RETURN]: {
      on: {
        [transitions.SEND_BACK]: states.RETURNED,
        [transitions.AWAITING_RETURN_TO_LOST]: states.LOST,
      },
    },
    [states.COMPLETED]: {
      on: {
        [transitions.REVIEW_1_BY_CUSTOMER]: states.REVIEWED_BY_CUSTOMER,
        [transitions.REVIEW_1_BY_PROVIDER]: states.REVIEWED_BY_PROVIDER,
        [transitions.EXPIRE_REVIEW_PERIOD]: states.REVIEWED,
      },
    },
    [states.REVIEWED_BY_CUSTOMER]: {
      on: {
        [transitions.REVIEW_2_BY_PROVIDER]: states.REVIEWED,
        [transitions.EXPIRE_PROVIDER_REVIEW_PERIOD]: states.REVIEWED,
      },
    },
    [states.REVIEWED_BY_PROVIDER]: {
      on: {
        [transitions.REVIEW_2_BY_CUSTOMER]: states.REVIEWED,
        [transitions.EXPIRE_CUSTOMER_REVIEW_PERIOD]: states.REVIEWED,
      },
    },
    [states.REVIEWED]: { type: 'final' },
  },
} as const;

export const getChatDisplayTransactionStatus = (
  lastTransition: (typeof transitions)[keyof typeof transitions],
  isSeller: boolean
) => {
  switch (lastTransition) {
    case '' || null:
      return '';
    case transitions.REQUEST_TO_BUY:
    case transitions.REQUEST_TO_BUY_AFTER_INQUIRY:
    case transitions.EDIT_LINE_ITEMS:
      return 'Payment error';
    case transitions.INQUIRE:
    case transitions.PAY:
      return 'Requested';
    case transitions.AUTO_REJECT:
      return 'Expired';
    case transitions.REJECT:
      return 'Declined';
    case transitions.ACCEPT:
      return 'Accepted';
    case transitions.FAIL_TO_SEND:
      return 'Failed to send';
    case transitions.SEND:
      return 'Shipped';
    case transitions.DELIVER:
      return 'Delivered';
    case transitions.COMPLETE:
    case transitions.SELLER_WINS_DISPUTE:
    case transitions.REVIEW_1_BY_CUSTOMER:
    case transitions.REVIEW_2_BY_CUSTOMER:
    case transitions.REVIEW_1_BY_PROVIDER:
    case transitions.REVIEW_2_BY_PROVIDER:
    case transitions.EXPIRE_REVIEW_PERIOD:
    case transitions.EXPIRE_CUSTOMER_REVIEW_PERIOD:
    case transitions.EXPIRE_PROVIDER_REVIEW_PERIOD:
      return isSeller ? 'Sold' : 'Yours';
    case transitions.DISPUTE:
      return 'In dispute';
    case transitions.BUYER_WINS_DISPUTE:
      return 'Disputed';
    default:
      return 'Other';
  }
};

export const isAwaitingReviewByCustomer = transition => {
  return [
    transitions.COMPLETE,
    transitions.SELLER_WINS_DISPUTE,
    transitions.REVIEW_1_BY_PROVIDER,
  ].includes(transition);
};

// Check if a transition is the kind that should be rendered
// when showing transition history (e.g. ActivityFeed)
// The first transition and most of the expiration transitions made by system are not relevant
export const isRelevantPastTransition = transition => {
  return [
    transitions.INQUIRE,
    transitions.REQUEST_TO_BUY_AFTER_INQUIRY,
    transitions.REQUEST_TO_BUY,
    transitions.EDIT_LINE_ITEMS,
    transitions.AUTO_REJECT,
    transitions.REJECT,
    transitions.ACCEPT,
    transitions.FAIL_TO_SEND,
    transitions.SEND,
    transitions.SENT_TO_LOST,
    transitions.REFUND_LOST,
    transitions.DELIVER,
    transitions.FAIL_TO_PICK_UP,
    transitions.DISPUTE,
    transitions.COMPLETE,
    transitions.SELLER_WINS_DISPUTE,
    transitions.BUYER_WINS_DISPUTE,
    transitions.SEND_BACK,
    transitions.AWAITING_RETURN_TO_LOST,
    transitions.REVIEW_1_BY_PROVIDER,
    transitions.REVIEW_2_BY_PROVIDER,
    transitions.REVIEW_1_BY_CUSTOMER,
    transitions.REVIEW_2_BY_CUSTOMER,
    transitions.EXPIRE_REVIEW_PERIOD,
    transitions.EXPIRE_PROVIDER_REVIEW_PERIOD,
    transitions.EXPIRE_CUSTOMER_REVIEW_PERIOD,
  ].includes(transition);
};

export const isCustomerReview = transition => {
  return [transitions.REVIEW_1_BY_CUSTOMER, transitions.REVIEW_2_BY_CUSTOMER].includes(transition);
};

export const isProviderReview = transition => {
  return [transitions.REVIEW_1_BY_PROVIDER, transitions.REVIEW_2_BY_PROVIDER].includes(transition);
};

// Check if the given transition is privileged.
//
// Privileged transitions need to be handled from a secure context,
// i.e. the backend. This helper is used to check if the transition
// should go through the local API endpoints, or if using JS SDK is
// enough.
export const isPrivileged = transition => {
  return [
    transitions.REQUEST_TO_BUY,
    transitions.REQUEST_TO_BUY_AFTER_INQUIRY,
    transitions.EDIT_LINE_ITEMS,
  ].includes(transition);
};

export const isPaymentUnsuccessful = transition => {
  return [
    transitions.REQUEST_TO_BUY,
    transitions.REQUEST_TO_BUY_AFTER_INQUIRY,
    transitions.EDIT_LINE_ITEMS,
  ].includes(transition);
};

// Check when transaction is completed (item is received and review notifications sent)
export const isCompleted = transition => {
  const txCompletedTransitions = [
    transitions.REVIEW_1_BY_CUSTOMER,
    transitions.REVIEW_1_BY_PROVIDER,
    transitions.REVIEW_2_BY_CUSTOMER,
    transitions.REVIEW_2_BY_PROVIDER,
    transitions.EXPIRE_REVIEW_PERIOD,
    transitions.EXPIRE_CUSTOMER_REVIEW_PERIOD,
    transitions.EXPIRE_PROVIDER_REVIEW_PERIOD,
    transitions.COMPLETE,
    transitions.SELLER_WINS_DISPUTE,
  ];
  return txCompletedTransitions.includes(transition);
};

// Check when transaction is refunded (order did not happen)
// In these transitions action/stripe-refund-payment is called
// TODO: Delete if won't be used
export const isRefunded = transition => {
  const txRefundedTransitions = [];
  // @ts-expect-error TS(2345) FIXME: Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
  return txRefundedTransitions.includes(transition);
};

// @ts-expect-error TS(2339) FIXME: Property 'PURCHASED' does not exist on type '{ INI... Remove this comment to see the full error message
export const statesNeedingProviderAttention = [states.PURCHASED];
