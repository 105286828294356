import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuPortal,
  DropdownMenuTrigger,
} from '@radix-ui/react-dropdown-menu';
import classNames from 'classnames';
import React, { useMemo } from 'react';

import { AspectRatioWrapper, NamedLink, PrimaryButton, ResponsiveImage } from '../../components';
import { useConfiguration } from '../../context/configurationContext';
import { formatMoney } from '../../util/currency';
import { ensureListing } from '../../util/data';
import { useIntl } from '../../util/reactIntl';
import { richText } from '../../util/richText';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  createSlug,
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
} from '../../util/urlHelpers';
import css from './ListingCard.module.css';
import { IconEllipsisHorizontal } from 'assets/icons';
import { Badge } from 'components/_chadcn/Badge';
import { ConditionType } from 'config/configListing';
import AddToFavoritesButton from 'containers/ListingPage/AddToFavoritesButton/AddToFavoritestButton';
import { ListingManagementButtons } from 'containers/ProfilePage/ListingManagementButtons/ListingManagementButtons';
import { useHandleListingAction } from 'hooks/api/listings/useHandleListingAction';
import { useCurrentUser } from 'hooks/selectors/useCurrentUser';
import { useGeolocation } from 'hooks/useGeolocation';

const MIN_LENGTH_FOR_LONG_WORDS = 10;

const priceData = (price, configCurrency, intl) => {
  if (!price?.amount || !price?.currency) {
    return {};
  }

  const formattedPrice = formatMoney(intl, price);

  // Always return the formatted price, even if currencies don't match
  return {
    formattedPrice,
    priceTitle: `${formattedPrice} (${price.currency})`,
    isConfigCurrency: price.currency === configCurrency,
  };
};

interface ListingCardProps {
  className?: string;
  rootClassName?: string;
  listing: any;
  showPriceInfo?: boolean;
  renderSizes?: string;
  setActiveListing?: (listingId) => void;

  addToFavoritesShown?: boolean;
  manageListingsShown?: boolean;
  addToFavoritesClass?: string;
  appendSizeToTitle?: boolean;
}

export const ListingCard: React.FC<ListingCardProps> = props => {
  const config = useConfiguration();
  const {
    className,
    rootClassName,
    listing,
    renderSizes,
    setActiveListing,
    showPriceInfo = true,
    addToFavoritesShown,
    manageListingsShown,
    addToFavoritesClass,
    appendSizeToTitle,
  } = props;

  const intl = useIntl();
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;
  const { title = '', price } = currentListing.attributes;
  const brandName = listing?.attributes?.publicData.brandName;
  const brandId = listing?.attributes?.publicData.brandId;

  const slug = createSlug(title);
  const firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;

  const originalPrice = new sdkTypes.Money(
    currentListing.attributes?.publicData?.priceWhenBought?.amount,
    currentListing.attributes?.publicData?.priceWhenBought?.currency
  );
  const isDraft = currentListing.attributes.state === 'draft';
  const isPendingApproval = currentListing.attributes.state === 'pendingApproval';
  const noStock = currentListing.currentStock?.attributes?.quantity === 0;
  const isClosed = currentListing.attributes.state === 'closed';
  const isDeleted = currentListing.attributes.publicData?.isDeleted;
  const isManuallySold = currentListing.attributes.publicData?.isManuallySold;
  const hasStatus =
    isDraft || isPendingApproval || noStock || isClosed || isDeleted || isManuallySold;

  const currentUser = useCurrentUser();
  const isOwnListing = currentUser?.id?.uuid === listing?.author?.id?.uuid;
  const isActive = isPendingApproval || (!isDraft && !noStock && !isClosed && !isDeleted);
  const isListingActionAllowed = isOwnListing && manageListingsShown;

  const size = currentListing.attributes?.publicData?.size?.toUpperCase();
  const shouldAppendSize = !title.endsWith(`(${size})`);
  const titleWithSize =
    appendSizeToTitle && size && shouldAppendSize ? `${title} (${size})` : title;

  const {
    aspectWidth = 3,
    aspectHeight = 4,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const variants = firstImage
    ? Object.keys(firstImage?.attributes?.variants).filter(k => k.startsWith(variantPrefix))
    : [];

  const { formattedPrice, priceTitle } = priceData(price, config.currency, intl);
  const { formattedPrice: formattedOriginalPrice, priceTitle: originalPriceTitle } = priceData(
    originalPrice,
    config.currency,
    intl
  );

  const { handleActivateListing, isActivateLoading, isRestockLoading } = useHandleListingAction();

  const isActivateInProgress = isManuallySold ? isRestockLoading : isActivateLoading;

  const setActivePropsMaybe = setActiveListing
    ? {
        onMouseEnter: () => setActiveListing(currentListing.id),
        onMouseLeave: () => setActiveListing(null),
      }
    : null;

  // If we have provided price info that means that the card should redirect you
  // somewhere on click, and when its not provided, we can see it as image only.
  const ListingCardWrapper = useMemo(
    () =>
      ({ children }) =>
        showPriceInfo && !noStock ? (
          <NamedLink
            className={classes}
            name={isDraft || isPendingApproval ? 'ListingPageVariant' : 'ListingPage'}
            params={{
              id,
              slug,
              ...(isDraft
                ? { variant: LISTING_PAGE_DRAFT_VARIANT }
                : isPendingApproval
                ? { variant: LISTING_PAGE_PENDING_APPROVAL_VARIANT }
                : {}),
            }}
          >
            {children}
          </NamedLink>
        ) : (
          <div className={classes}>{children}</div>
        ),
    [classes, id, isDraft, isPendingApproval, noStock, showPriceInfo, slug]
  );

  const { userGeolocation } = useGeolocation();
  const showOnlyShippedToUKBadge =
    isActive &&
    userGeolocation !== 'GB' &&
    currentListing.attributes?.publicData?.condition !== ConditionType.SampleSale;

  return (
    <ListingCardWrapper>
      <AspectRatioWrapper
        className={css.aspectRatioWrapper}
        width={aspectWidth}
        height={aspectHeight}
        {...setActivePropsMaybe}
      >
        {showOnlyShippedToUKBadge && (
          <Badge
            variant="accent"
            className="top-2 left-2 font-medium px-1 py-.25 text-0000 absolute uppercase text-center z-10"
          >
            UK Shipping Only
          </Badge>
        )}
        <ResponsiveImage
          rootClassName={css.rootForImage}
          alt={title}
          image={firstImage}
          variants={variants}
          sizes={renderSizes}
          loading="lazy"
          objectFit="contain"
        />
        {addToFavoritesShown && isActive && (
          <AddToFavoritesButton listing={currentListing} className={addToFavoritesClass} />
        )}
        {hasStatus && (
          <div className={css.overlayInfo}>
            <div className={css.overlayInfo__container}>
              <span>
                {noStock ? 'Sold' : isDraft ? 'Draft' : isClosed ? 'Closed' : 'Pending Approval'}
              </span>
              {isListingActionAllowed && isDraft && (
                <>
                  <NamedLink
                    name="EditListingPage"
                    params={{
                      id: listing.id.uuid,
                      slug: createSlug(listing.attributes.title),
                      tab: 'photos',
                      type: 'edit',
                    }}
                  >
                    <PrimaryButton className={css.transparentButton}>EDIT</PrimaryButton>
                  </NamedLink>
                  <span className={css.buttonInfoText}>Finish and submit for review</span>
                </>
              )}
              {isListingActionAllowed && (isClosed || isManuallySold) && (
                <>
                  <PrimaryButton
                    className={css.transparentButton}
                    onClick={async () => {
                      await handleActivateListing(id, isManuallySold);
                    }}
                    disabled={isActivateInProgress}
                    inProgress={isActivateInProgress}
                  >
                    ACTIVATE
                  </PrimaryButton>
                  <span className={css.buttonInfoText}>Make available for sale</span>
                </>
              )}{' '}
            </div>
          </div>
        )}
        {isListingActionAllowed && (!noStock || isManuallySold) && (
          <DropdownMenu>
            <DropdownMenuTrigger className={css.manageMenuTrigger}>
              <IconEllipsisHorizontal />
            </DropdownMenuTrigger>
            <DropdownMenuPortal>
              <DropdownMenuContent sideOffset={16}>
                <ListingManagementButtons
                  isActive={isActive}
                  isDraft={isDraft}
                  listing={listing}
                  isClosed={isClosed}
                  isManuallySold={isManuallySold}
                />
              </DropdownMenuContent>
            </DropdownMenuPortal>
          </DropdownMenu>
        )}
      </AspectRatioWrapper>
      <div className={css.info}>
        {brandName && (
          <NamedLink name="SearchPage" to={{ search: `?brandIds=${brandId}` }}>
            <h2 className={css.brandName}>{brandName}</h2>
          </NamedLink>
        )}
        <div className={css.title}>
          {richText(titleWithSize, {
            longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
            longWordClass: css.longWord,
          })}
        </div>
        {showPriceInfo && (
          <div className={css.price}>
            <div className={css.price__value} title={priceTitle}>
              {formattedPrice}
            </div>
            <div className={css.price__originalValue} title={originalPriceTitle}>
              {formattedOriginalPrice}
            </div>
          </div>
        )}
      </div>
    </ListingCardWrapper>
  );
};

export default ListingCard;
