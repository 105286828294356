import React, { memo } from 'react';
import { useEffect } from 'react';
import { Field } from 'react-final-form';
import PhoneInput from 'react-phone-number-input';

import ValidationError from '../ValidationError/ValidationError';

const PhoneField = memo(
  ({
    // @ts-expect-error TS(2339) FIXME: Property 'input' does not exist on type '{ childre... Remove this comment to see the full error message
    input: { onChange, onFocus, onBlur, value },
    // @ts-expect-error TS(2339) FIXME: Property 'className' does not exist on type '{ chi... Remove this comment to see the full error message
    className,
    // @ts-expect-error TS(2339) FIXME: Property 'label' does not exist on type '{ childre... Remove this comment to see the full error message
    label,
    // @ts-expect-error TS(2339) FIXME: Property 'customErrorText' does not exist on type ... Remove this comment to see the full error message
    customErrorText,
    // @ts-expect-error TS(2339) FIXME: Property 'meta' does not exist on type '{ children... Remove this comment to see the full error message
    meta,
    ...rest
  }) => {
    const { valid, invalid, touched, error } = meta;
    const errorText = customErrorText || error;
    const hasError = !!customErrorText || !!(touched && invalid && error);

    const fieldMeta = { touched: hasError, error: errorText };

    return (
      <div className={className}>
        <label>{label}</label>
        <div
          // @ts-expect-error TS(2322) FIXME: Type '{ children: Element; tabindex: string; class... Remove this comment to see the full error message
          tabindex="0"
          onFocus={event => onFocus(event)}
          onBlur={event => onBlur(event)}
        >
          <PhoneInput value={value} onChange={p => onChange(p)} {...rest} />
        </div>
        <ValidationError fieldMeta={fieldMeta} />
      </div>
    );
  }
);

const InternationalPhoneNumberInput = props => {
  const { name, id, label } = props;

  useEffect(() => {
    if (typeof window !== 'undefined') {
      require('react-phone-number-input/style.css');
    }
  }, []);

  return <Field name={name} label={label} component={PhoneField} {...props} />;
};

export default InternationalPhoneNumberInput;
