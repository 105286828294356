import classNames from 'classnames';
import React from 'react';

import css from './MobileBottomNavigation.module.css';
import { IconHome, IconInbox, IconNoldrobe, IconPlus, IconWallet } from 'assets/icons';
import NamedLink from 'components/NamedLink/NamedLink';
import { ProfileTab } from 'containers/ProfilePage/ProfilePage';
import { WalletTab } from 'containers/WalletPage/WalletPage';
import { useCurrentUser } from 'hooks/selectors/useCurrentUser';

const MobileBottomNavigation: React.FC<{
  className?: string;
}> = ({ className }) => {
  const currentUser = useCurrentUser();
  const userUuid = currentUser?.id?.uuid;

  return (
    <div className={classNames(css.root, className)}>
      <div className={css.content}>
        <NamedLink name="LandingPage" className={css.menuItem}>
          <IconHome />
          <span>Home</span>
        </NamedLink>
        <NamedLink
          name={userUuid ? 'ProfilePage' : 'LoginPage'}
          params={userUuid ? { id: userUuid } : undefined}
          className={css.menuItem}
          to={userUuid ? { search: `profileTab=${ProfileTab.LISTINGS}` } : undefined}
        >
          <IconNoldrobe />
          <span>Noldrobe</span>
        </NamedLink>
        <NamedLink name="ResellLandingPage" className={css.menuItemCenter}>
          <div>
            <IconPlus />
          </div>
        </NamedLink>
        <NamedLink name="ChatPage" className={css.menuItem}>
          <IconInbox />
          <span>Messages</span>
        </NamedLink>
        <NamedLink
          name={userUuid ? 'WalletPage' : 'LoginPage'}
          params={userUuid ? { id: userUuid } : undefined}
          className={css.menuItem}
          // TODO: Add type safety
          to={userUuid ? { search: `profileTab=${WalletTab.PAYOUTS}` } : undefined}
        >
          <IconWallet />
          <span>Wallet</span>
        </NamedLink>
      </div>
    </div>
  );
};

export default MobileBottomNavigation;
