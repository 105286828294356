import classNames from 'classnames';
import React from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { compose } from 'redux';

import {
  IconSpinner,
  LayoutSingleColumn,
  MobileBottomNavigation,
  NamedLink,
  NamedRedirect,
  Page,
  PaginationLinks,
  PrimaryButton,
  Reviews,
} from '../../components';
import { useConfiguration } from '../../context/configurationContext';
import { isScrollingDisabled } from '../../ducks/ui.duck';
import { useGetFavouriteListings } from '../../hooks/api/listings/useGetFavouriteListings';
import { useGetUserReviews } from '../../hooks/api/reviews/useGetUserReviews';
import { useQueryParams } from '../../hooks/useQueryParams';
import { FormattedMessage } from '../../util/reactIntl';
import { REVIEW_TYPE_OF_PROVIDER } from '../../util/types';
import FooterContainer from '../FooterContainer/FooterContainer';
import NotFoundPage from '../NotFoundPage/NotFoundPage';
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import { Impact } from './Impact/Impact';
import MyOrders from './MyOrders/MyOrders';
import ProfileHeader from './ProfileHeader/ProfileHeader';
import { ProfileListings } from './ProfileListings/ProfileListings';
import { useProfileTabs } from './ProfilePage.hooks';
import css from './ProfilePage.module.css';
import ProfileTabNav from './ProfileTabNav/ProfileTabNav';
import { StarRatingWithNumber } from './StarRating/StarRating';
import { IconPlus } from 'assets/icons';
import { useGetUserListings } from 'hooks/api/listings/useGetUserListings';
import { useGetUserSocials, useToggleFollowUser } from 'hooks/api/users';
import { useGetUser } from 'hooks/api/users/useGetUser';
import { useCurrentUser } from 'hooks/selectors/useCurrentUser';
import { useIsScrollingDisabled } from 'hooks/selectors/useIsScrollingDisabled';

export const ProfileTab = {
  LISTINGS: 'listings',
  FAVOURITES: 'favourites',
  ORDERS: 'orders',
  REVIEWS: 'review',
};

type ProfilePageProps = {
  currentUser: any;
  reviews: any[];
  queryReviewsError: any;
};

const ProfilePageComponent: React.FC<ProfilePageProps> = props => {
  const { currentUser: currentUserUnensured, ...rest } = props;

  const { id, handle } = useParams<{
    id?: string;
    handle?: string;
  }>();
  const {
    data: profileUser,
    error: getUserError,
    isLoading,
    isSuccess,
  } = useGetUser(id || handle || '');
  const intl = useIntl();
  const currentUser = useCurrentUser();

  const config = useConfiguration();
  const { displayName } = profileUser?.attributes?.profile || {};
  const schemaTitleVars = { name: displayName, marketplaceName: config.marketplaceName };
  const schemaTitle = intl.formatMessage({ id: 'ProfilePage.schemaTitle' }, schemaTitleVars);
  const search = useLocation().search;

  const scrollingDisabled = useIsScrollingDisabled();

  if (getUserError) {
    return <NotFoundPage />;
  }

  if (isSuccess && id && profileUser?.attributes?.profile?.publicData?.handle) {
    return (
      <NamedRedirect
        name="ProfilePageWithHandle"
        params={{ handle: profileUser?.attributes?.profile?.publicData?.handle }}
        search={search}
      />
    );
  }

  return (
    <Page
      scrollingDisabled={scrollingDisabled}
      title={schemaTitle}
      schema={{
        '@context': 'https://schema.org',
        '@type': 'ProfilePage',
        name: schemaTitle,
      }}
    >
      <LayoutSingleColumn
        topbar={<TopbarContainer currentPage="ProfilePage" />}
        footer={<MobileBottomNavigation />}
      >
        {isLoading ? (
          <div style={{ display: 'grid', height: 'calc(100vh)', inset: 0, placeItems: 'center' }}>
            <IconSpinner />
          </div>
        ) : (
          <MainContent
            currentUser={currentUser}
            profileUser={profileUser}
            getUserError={getUserError}
            {...rest}
          />
        )}
        <FooterContainer />
      </LayoutSingleColumn>
    </Page>
  );
};

const MainContent = props => {
  const { getUserError, queryReviewsError, profileUser, currentUser } = props;

  const {
    data: userListingsData,
    isLoading: isLoadingUserListings,
    error: queryListingsError,
    fetchNextPage: fetchNextPageUserListings,
    hasNextPage: hasNextPageUserListings,
  } = useGetUserListings({ userId: profileUser?.id?.uuid });

  const userListings = userListingsData?.pages.flatMap(page => page.listings) || [];

  const loadMoreUserListings = () => {
    if (hasNextPageUserListings) {
      fetchNextPageUserListings();
    }
  };

  const ownProfile = currentUser && profileUser && currentUser?.id?.uuid === profileUser?.id?.uuid;

  const {
    data: favouritesData,
    isLoading: isLoadingFavourites,
    fetchNextPage: fetchNextPageFavourites,
    hasNextPage: hasNextPageFavourites,
  } = useGetFavouriteListings({});

  const favouriteListings = favouritesData?.pages.flatMap(page => page.listings) || [];

  const loadMoreFavourites = () => {
    if (hasNextPageFavourites) {
      fetchNextPageFavourites();
    }
  };

  // TODO: Calculate average rating and save to profile
  const averageProviderRating = NaN;

  const { currentProfileTab } = useProfileTabs();
  const { mutate: toggleFollowUser } = useToggleFollowUser();
  const { data: userSocials } = useGetUserSocials();

  if (getUserError || queryListingsError) {
    return (
      <p className={css.error}>
        <FormattedMessage id="ProfilePage.loadingDataFailed" />
      </p>
    );
  }

  return (
    <div className={css.profileContainer}>
      <ProfileHeader
        profileUser={profileUser}
        ownProfile={ownProfile}
        className={css.maxWidthContainer}
      />
      {ownProfile ? (
        <div className={css.wrapper}>
          {/* TODO: Make accessible and SEO friendly */}
          <ProfileTabNav />

          {currentProfileTab === ProfileTab.LISTINGS && (
            <>
              <Impact
                profileUser={profileUser}
                ownProfile={ownProfile}
                className={css.maxWidthContainer}
              />
              {userListings.length > 0 ? (
                <ProfileListings
                  listings={userListings}
                  className={css.maxWidthContainer}
                  hasMore={!!hasNextPageUserListings}
                  loadMore={loadMoreUserListings}
                />
              ) : !isLoadingUserListings ? (
                <div className={css.emptyStateWithButton}>
                  <span>
                    Hey! We can't wait for you to start selling, earning and making a difference!
                  </span>
                  <NamedLink name="ResellLandingPage">
                    <PrimaryButton>
                      <IconPlus />
                      Add a listing
                    </PrimaryButton>
                  </NamedLink>
                </div>
              ) : (
                <div className="grid place-content-center py-8">
                  <IconSpinner />
                </div>
              )}
            </>
          )}
          {currentProfileTab === ProfileTab.REVIEWS && (
            <ProfileReviews
              ownProfile={ownProfile}
              averageRating={averageProviderRating}
              queryReviewsError={queryReviewsError}
            />
          )}
          {currentProfileTab === ProfileTab.ORDERS && <MyOrders />}
          {currentProfileTab === ProfileTab.FAVOURITES && (
            <>
              {favouriteListings?.length > 0 ? (
                <ProfileListings
                  listings={favouriteListings}
                  className={css.maxWidthContainer}
                  loadMore={loadMoreFavourites}
                  hasMore={!!hasNextPageFavourites}
                />
              ) : !isLoadingFavourites ? (
                <div className={css.emptyStateWithButton}>
                  <span>
                    You haven’t added any listings to your favourites yet.
                    <br /> Start adding listings to your favourites to keep track of them.
                  </span>
                  <NamedLink name="SearchPage">
                    <PrimaryButton>Browse listings</PrimaryButton>
                  </NamedLink>
                </div>
              ) : (
                <div className="grid place-content-center py-8">
                  <IconSpinner />
                </div>
              )}
            </>
          )}
        </div>
      ) : (
        <>
          <PrimaryButton
            className={css.mobileFollowButton}
            onClick={() => toggleFollowUser(profileUser?.id?.uuid)}
          >
            {userSocials?.followingIds?.includes(profileUser.id?.uuid) ? 'Unfollow' : 'Follow'}
          </PrimaryButton>
          <Impact
            profileUser={profileUser}
            ownProfile={ownProfile}
            className={css.maxWidthContainer}
          />

          <ProfileListings
            listings={userListings}
            className={css.maxWidthContainer}
            hasMore={!!hasNextPageUserListings}
            loadMore={loadMoreUserListings}
          />
          {Boolean(isLoadingUserListings && !userListings.length) && (
            <div className={css.emptyStateWithButton}>This user doesn't have any listings yet</div>
          )}
          <ProfileReviews
            ownProfile={ownProfile}
            averageRating={averageProviderRating}
            queryReviewsError={queryReviewsError}
            className={css.maxWidthContainer}
          />
        </>
      )}
    </div>
  );
};

export const ProfileReviews = props => {
  const { queryReviewsError, averageRating, className, ownProfile } = props;

  const [{ page }] = useQueryParams<{ page: string }>({ page: '1' });
  const { data: reviewsData, isFetched } = useGetUserReviews({
    type: REVIEW_TYPE_OF_PROVIDER,
    page,
  });
  const reviews = reviewsData?.reviews || [];
  const totalPages = reviewsData?.totalPages || 0;
  const totalReviews = reviewsData?.totalItems || 0;

  const params = useParams();

  return (
    <div className={classNames(css.mobileReviews, className)}>
      {Boolean(reviews.length) && (
        <>
          <div className={css.profileReviewsWrapper}>
            <h2 className={css.mobileReviewsTitle}>
              <FormattedMessage id="ProfilePage.reviews.title" />
            </h2>
            <span className={css.ratingWrapper}>
              <StarRatingWithNumber rating={averageRating} style={{ marginTop: -4 }} />
              <span className={css.verticalDivider} />
              <span style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                <FormattedMessage
                  id="ProfilePage.reviews.averageRating"
                  values={{ numReviews: totalReviews }}
                />
              </span>
            </span>
          </div>
          <ReviewsErrorMaybe queryReviewsError={queryReviewsError} />
          <Reviews reviews={reviews} />
          <PaginationLinks
            className={css.mobileReviewsPagination}
            pagePathParams={params}
            pageName="ProfilePageWithHandle"
            totalPages={totalPages}
          />
        </>
      )}
      {Boolean(isFetched && !reviews.length) &&
        (ownProfile ? (
          <div className={css.emptyStateWithButton}>
            <FormattedMessage id="ProfilePage.reviews.empty" />
            <NamedLink name="ResellLandingPage">
              <PrimaryButton>
                <IconPlus />
                Add a listing
              </PrimaryButton>
            </NamedLink>
          </div>
        ) : null)}
    </div>
  );
};

export const ReviewsErrorMaybe = props => {
  const { queryReviewsError } = props;
  return queryReviewsError ? (
    <p className={css.error}>
      <FormattedMessage id="ProfilePage.loadingReviewsFailed" />
    </p>
  ) : null;
};

const mapStateToProps = state => {
  const { reviews, queryReviewsError } = state.ProfilePage;

  return {
    scrollingDisabled: isScrollingDisabled(state),
    reviews,
    queryReviewsError,
  };
};

const ProfilePage = compose(connect(mapStateToProps))(ProfilePageComponent);

export default ProfilePage;
