import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import { logoutSuccess, userLogout } from '../../../ducks/auth.duck';
import { clearCurrentUser } from '../../../ducks/user.duck';
import * as log from '../../../util/log';
import { useSdk } from 'util/sdkContext';

export const useLogout = () => {
  const dispatch = useDispatch();
  const sdk = useSdk();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => sdk.logout(),
    onSuccess: () => {
      dispatch(logoutSuccess());
      dispatch(clearCurrentUser());
      log.clearUserId();
      dispatch(userLogout());
    },
    onSettled: () => {
      // TODO: Jotai clear all atoms
      queryClient.clear();
    },
    meta: { name: 'logout' },
  });
};
