import { z } from 'zod';

import { parseSearchQueryParams, useQueryParams } from 'hooks/useQueryParams';
import { DEFAULT_SORTING } from 'util/constants';

const stringArray = z.array(z.string());
const productTypesSchema = z
  .union([z.string(), z.array(z.string())])
  .transform(v => (Array.isArray(v) ? v : [v])) as unknown as typeof stringArray;
const colorsSchema = z
  .union([z.string(), z.array(z.string())])
  .transform(v => (Array.isArray(v) ? v : [v])) as unknown as typeof stringArray;
const sizesSchema = z
  .union([z.string(), z.number(), z.array(z.string().or(z.number()))])
  .transform(v => (Array.isArray(v) ? v : [v])) as unknown as typeof stringArray;

const numbersArray = z.array(z.number());
const brandIdsSchema = z
  .union([z.number(), z.array(z.number())])
  .transform(v => (Array.isArray(v) ? v : [v])) as unknown as typeof numbersArray;

export const filtersQueryParamsSchema = z.object({
  brandIds: brandIdsSchema,
  category: z.string(),
  // TODO: Should be enum
  sortBy: z.string(),
  department: z.string(),
  condition: z.string(),
  productType: productTypesSchema,
  colors: colorsSchema,
  priceRange: z.tuple([z.coerce.number(), z.coerce.number()]),
  text: z.string(),
  sizes: sizesSchema,
  availability: z.enum(['all', 'hide_sold']),
  preloved: z.boolean(),
});

export type FiltersQueryParams = z.infer<typeof filtersQueryParamsSchema>;

export function useFilterQueryParams() {
  const defaultFilters = FILTERS_DEFAULTS;
  const [params, setParams, generateNewParams] = useQueryParams(
    defaultFilters,
    filtersQueryParamsSchema
  );

  return [params, setParams, generateNewParams] as const;
}

export const DEFAULT_PRICE_RANGE = [0, 10000] as const;
export const RESETTABLE_FILTERS_DEFAULTS: Pick<
  FiltersQueryParams,
  | 'department'
  | 'productType'
  | 'condition'
  | 'colors'
  | 'priceRange'
  | 'text'
  | 'brandIds'
  | 'sizes'
  | 'preloved'
> = {
  text: '',
  department: '',
  productType: [],
  colors: [],
  condition: '',
  priceRange: [...DEFAULT_PRICE_RANGE],
  brandIds: [],
  sizes: [],
  preloved: false,
};
export const FILTERS_DEFAULTS: FiltersQueryParams = {
  ...RESETTABLE_FILTERS_DEFAULTS,
  category: '',
  // TODO: Should be type-checked
  sortBy: DEFAULT_SORTING,
  availability: 'all',
};

export const parseFiltersQueryParams = (search: string) => {
  return parseSearchQueryParams(search, FILTERS_DEFAULTS, filtersQueryParamsSchema);
};

export type GetListingsWithFiltersRequest = {
  brandIds?: number[];
  category?: string;
  sortBy?: string;
  department?: string;
  condition?: string;
  productType?: string[];
  colors?: string[];
  priceRange?: [number, number];
  text?: string;
  sizes?: (string | number)[];
  availability?: 'all' | 'hide_sold';
  preloved?: boolean;
  page?: number;
  perPage?: number;
  country?: string;
};

export type GetListingsRequest = {
  page?: number;
  perPage?: number;
  country?: string;
};
