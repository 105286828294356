import { useQuery } from '@tanstack/react-query';

import { useCurrentUser } from 'hooks/selectors/useCurrentUser';
import { get } from 'util/httpsClient';

export const useGetConfiguration = () => {
  const currentUser = useCurrentUser();

  return useQuery({
    enabled: Boolean(currentUser.id?.uuid),
    queryKey: ['configuration'],
    queryFn: () => {
      return get({ path: '/configuration' });
    },
  });
};
