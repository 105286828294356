import classNames from 'classnames';
import { arrayOf, string } from 'prop-types';
import React from 'react';

import { Avatar } from '../../components';
import StarRating from '../../containers/ProfilePage/StarRating/StarRating';
import { useIntl } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import css from './Reviews.module.css';

const Review: React.FC<{
  review: any;
}> = ({ review }) => {
  const intl = useIntl();

  const date = review.attributes.createdAt;
  const dateString = intl.formatDate(date, { day: 'numeric', month: 'short', year: 'numeric' });

  return (
    <div className={css.review}>
      <Avatar className={css.avatar} user={review.author} />
      <div>
        <div className={css.headerContainer}>
          <span className={css.authorName}>{review.author.attributes.profile.displayName}</span>
          <span className={css.date}>{dateString}</span>
        </div>
        <StarRating rating={review.attributes.rating} style={{ marginLeft: '-4px' }} />
        <p className={css.reviewContent}>{review.attributes.content}</p>
      </div>
    </div>
  );
};

type ReviewsProps = {
  className?: string;
  rootClassName?: string;
  reviews?: any[];
};

const Reviews: React.FC<ReviewsProps> = props => {
  const { className, rootClassName, reviews = [] } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <ul className={classes}>
      {reviews.map(r => {
        return (
          <li key={`Review_${r.id.uuid}`} className={css.reviewItem}>
            <Review review={r} />
          </li>
        );
      })}
    </ul>
  );
};

export default Reviews;
