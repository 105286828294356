import { Elements, ExpressCheckoutElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { LineItem, StripeExpressCheckoutElementConfirmEvent } from '@stripe/stripe-js';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useHistory } from 'react-router-dom';

import { useSaveAddressIfNonExistent } from './ExpressCheckout.hooks';
import { ConditionType } from 'config/configListing';
import { stripePromise } from 'config/configStripe';
import { DiscountCodeState } from 'containers/CheckoutPage/CheckoutPage';
import {
  getShippingRatesQueryFn,
  useConfirmPayment,
  useRedirectToOrdersPage,
} from 'containers/CheckoutPage/CheckoutPage.hooks';
import { useRouteConfiguration } from 'context/routeConfigurationContext';
import {
  useGetSpeculativeTransaction,
  useInitiateOrder,
} from 'hooks/api/transactions/useInitiateOrder';
import { useGeolocation } from 'hooks/useGeolocation';
import { EuCountryCode } from 'translations/countryCodes';
import { post } from 'util/httpsClient';
import { logger } from 'util/log';
import { pathByRouteName } from 'util/routes';

type P = {
  listingId: string;
  listing: any;
};

const ExpressCheckout: React.FC<P> = ({ listingId, listing }) => {
  const history = useHistory();
  const routeConfiguration = useRouteConfiguration();
  const [showModal, setShowModal] = useState(false);
  const [useNoldCredit, setUseNoldCredit] = useState(false);
  const [initialAmountAndCurrency, setInitialAmountAndCurrency] = useState<{
    amount: number;
    currency: string;
  }>();
  const { userGeolocation } = useGeolocation();

  const [orderData, setOrderData] = useState({
    shippingAddressId: '',
    selectedRateId: '',
    billingAddressId: null,
    discountCodes: [] as string[],
    useNoldCredit: false,
    noldCreditAmount: 0,
    deliveryMethod: '',
    authenticate: false,
  });
  const [discountCodeState, setDiscountCodeState] = useState<DiscountCodeState>({
    input: '',
    error: '',
    isError: false,
    isApplied: false,
  });

  const {
    data: speculatedTransaction,
    isLoading: isLoadingSpeculatedTransaction,
    error: speculatedTransactionError,
  } = useGetSpeculativeTransaction({
    // TODO: Remove unnecessary isSpeculative
    isSpeculative: true,
    rateId: orderData.selectedRateId,
    listingId,
    shippingMethodId: orderData.deliveryMethod,
    discountCodes: orderData.discountCodes,
    useNoldCredit: orderData.useNoldCredit,
    authenticate: orderData.authenticate,
    shippingToAddressId: 0,
    billingAddressId: null,
    isExpressCheckout: true,
    userGeolocation: userGeolocation || 'GB',
    shippingToCountry: userGeolocation || 'GB',
  });

  if (speculatedTransactionError) {
    if (speculatedTransactionError.data?.errors[0]?.type === 'DiscountCodeError') {
      console.error('Failed to apply discount code:', speculatedTransactionError);
      const errorMessage =
        speculatedTransactionError.data?.errors?.[0]?.displayMessage ||
        'Failed to apply discount code. Please try again.';

      setDiscountCodeState(prev => ({
        ...prev,
        error: errorMessage,
        isError: true,
        isApplied: false,
      }));

      setOrderData(prev => ({
        ...prev,
        discountCodes: prev.discountCodes.slice(0, -1),
      }));
    } else if (speculatedTransactionError.data?.errors[0]?.type === 'OutOfStockError') {
      console.error('Out of stock error:', speculatedTransactionError);
      toast.error('Sorry, this item is no longer available', {
        duration: 10000,
      });

      const searchPagePath = pathByRouteName('SearchPage', routeConfiguration);
      history.push(`${searchPagePath}`);
    }
  }

  const handleApplyDiscountCode = async code => {
    if (orderData.discountCodes.includes(code)) {
      setDiscountCodeState(prev => ({
        ...prev,
        error: 'This discount code has already been applied',
      }));
      return;
    }

    setDiscountCodeState(prev => ({
      ...prev,
      error: '',
      isError: false,
      isApplied: true,
    }));

    setOrderData(prev => ({ ...prev, discountCodes: [...prev.discountCodes, code] }));
  };

  const handleRemoveDiscountCode = code => {
    setDiscountCodeState({
      error: '',
      isError: false,
      isApplied: false,
      input: '',
    });

    setOrderData(prev => ({
      ...prev,
      discountCodes: prev.discountCodes.filter(c => c !== code),
    }));
  };

  useEffect(() => {
    setOrderData(prevData => ({
      ...prevData,
      useNoldCredit,
    }));
  }, [useNoldCredit]);

  useEffect(() => {
    if (!speculatedTransaction || initialAmountAndCurrency) {
      return;
    }

    let amount = speculatedTransaction.attributes.payinTotal.amount;
    let currency = speculatedTransaction.attributes.payinTotal.currency.toLowerCase();
    const convertedTotal = speculatedTransaction.attributes.protectedData.convertedTotal;
    if (convertedTotal) {
      amount = convertedTotal.amount;
      currency = convertedTotal.currency.toLowerCase();
    }

    setInitialAmountAndCurrency({ amount, currency });
  }, [initialAmountAndCurrency, speculatedTransaction]);

  if (!initialAmountAndCurrency || !listing) {
    return <div />;
  }

  return (
    <>
      {/* <PrimaryButton onClick={() => setShowModal(true)}>
        <span className="hidden sm:inline">Buy With</span>
        <span
          className="capitalize tracking-tight text-2 -mt-.25 font-medium"
          style={{
            fontFamily: '-apple-system,system-ui,BlinkMacSystemFont',
          }}
        >
           Pay
        </span>
      </PrimaryButton>
      <Modal open={showModal} onOpenChange={setShowModal} title="Express Checkout">
        <div className="space-y-2">
          <DiscountCodeInput
            onApply={handleApplyDiscountCode}
            onRemove={handleRemoveDiscountCode}
            discountCodeState={discountCodeState}
            setDiscountCodeState={setDiscountCodeState}
          />
          <FieldCheckbox
            id="useNoldCredit"
            name="useNoldCredit"
            label="Use NOLD credit"
            checked={useNoldCredit}
            onCheckedChange={c => setUseNoldCredit(c)}
          />
          {discountCodeState.error && <p className="text-red-500">{discountCodeState.error}</p>} */}
      <Elements
        stripe={stripePromise}
        options={{
          mode: 'payment',
          amount: initialAmountAndCurrency!.amount,
          currency: initialAmountAndCurrency!.currency,
          capture_method: 'manual',
          appearance: {
            variables: {
              borderRadius: '50%',
            },
          },
        }}
      >
        <ExpressCheckoutHelper
          listingId={listingId}
          listing={listing}
          orderData={orderData}
          setOrderData={setOrderData}
          speculatedTransaction={speculatedTransaction}
          isLoadingSpeculatedTransaction={isLoadingSpeculatedTransaction}
        />
      </Elements>
      {/* </div>
      </Modal> */}
    </>
  );
};

const ExpressCheckoutHelper: React.FC<{
  listingId: string;
  listing: any;
  orderData: any;
  setOrderData: React.Dispatch<React.SetStateAction<any>>;
  speculatedTransaction: any;
  isLoadingSpeculatedTransaction: boolean;
}> = ({
  listingId,
  listing,
  orderData,
  setOrderData,
  speculatedTransaction,
  isLoadingSpeculatedTransaction,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const shippingToCountryRef = React.useRef('');
  const { mutateAsync: initiateOrder } = useInitiateOrder();
  const { mutateAsync: confirmPayment } = useConfirmPayment();
  const saveAddressIfNonExistent = useSaveAddressIfNonExistent();
  const redirectToOrdersPage = useRedirectToOrdersPage();
  const { userGeolocation } = useGeolocation();

  if (!elements || !stripe) {
    return null;
  }

  const isSampleSale = listing.attributes.publicData.condition === ConditionType.SampleSale;
  const allowedShippingCountries = ['GB', ...(isSampleSale ? Object.values(EuCountryCode) : [])];

  return (
    <ExpressCheckoutElement
      options={{
        paymentMethods: {
          applePay: 'always',
          amazonPay: 'never',
          paypal: 'never',
          link: 'never',
          googlePay: 'never',
        },
      }}
      onClick={async handler => {
        if (isLoadingSpeculatedTransaction) {
          return;
        }

        handler.resolve({
          emailRequired: true,
          lineItems: [],
          shippingAddressRequired: true,
          billingAddressRequired: true,
          phoneNumberRequired: true,
          allowedShippingCountries,
          shippingRates: [
            {
              id: 'none',
              displayName: 'Enter an address',
              amount: 0,
            },
          ],
        });
      }}
      onShippingRateChange={async event => {
        const speculativeTransaction = await initiateOrder({
          isSpeculative: true,
          listingId,
          rateId: event.shippingRate.id,
          shippingMethodId: event.shippingRate.id,
          userGeolocation: userGeolocation || 'GB',
          shippingToAddressId: 0,
          shippingToCountry: shippingToCountryRef.current,
          discountCodes: orderData.discountCodes,
          useNoldCredit: orderData.useNoldCredit,
          authenticate: orderData.authenticate,
          billingAddressId: null,
          isExpressCheckout: true,
        });

        const lineItems = speculativeTransaction.attributes.protectedData.convertedLineItems.length
          ? speculativeTransaction.attributes.protectedData.convertedLineItems
          : speculativeTransaction.attributes.lineItems;
        const stripeLineItems = lineItems
          .filter(item => item.includeFor?.includes('customer'))
          .map(item => ({
            name: item.code
              .replace('line-item/', '')
              .replaceAll('-', ' ')
              .replace(/\b(\w)/g, function (firstLetter) {
                return firstLetter.toUpperCase();
              }),
            amount: item.unitPrice.amount,
          }));

        const total =
          speculativeTransaction.attributes.protectedData.convertedTotal ||
          speculativeTransaction.attributes.payinTotal;

        elements?.update({
          amount: total.amount,
          currency: total.currency.toLowerCase(),
        });
        event.resolve({
          lineItems: stripeLineItems,
        });
      }}
      onShippingAddressChange={async event => {
        const res = await getShippingRatesQueryFn({
          listingId,
          userGeolocation,
          address: {
            zip: event.address.postal_code,
            city: event.address.city,
            country: event.address.country,
          },
        })();

        const rates = res.map(rate => ({
          id: rate.id,
          displayName: rate.name,
          amount: Math.round(parseFloat(rate.amount) * 100),
        }));
        if (rates.length === 0) {
          return event.reject();
        }

        shippingToCountryRef.current = event.address.country;
        event.resolve({
          shippingRates: rates,
        });
      }}
      onConfirm={async function (event: StripeExpressCheckoutElementConfirmEvent) {
        const [shippingAddress, billingAddress] = await Promise.all([
          saveAddressIfNonExistent({
            address: event.shippingAddress!.address,
            name: event.shippingAddress!.name || event.billingDetails?.name || '',
            phone: event.billingDetails?.phone || '',
          }),
          saveAddressIfNonExistent({
            address: event.billingDetails!.address,
            name: event.billingDetails?.name || event.shippingAddress?.name || '',
            phone: event.billingDetails?.phone || '',
          }),
        ]);
        if (!shippingAddress || !billingAddress) {
          logger.error('Failed to save shipping or billing address');
          return event.paymentFailed();
        }

        const rates = await getShippingRatesQueryFn({
          listingId,
          buyerAddressId: shippingAddress.id,
          userGeolocation,
        })();
        const fullRate = rates.find(
          rate =>
            rate.name === event.shippingRate!.displayName &&
            Math.round(parseFloat(rate.amount) * 100) === event.shippingRate!.amount
        );

        const response = await initiateOrder({
          isSpeculative: false,
          listingId,
          shippingToAddressId: Number(shippingAddress.id),
          billingAddressId: Number(billingAddress.id),
          rateId: fullRate.id,
          temporaryRateId: event.shippingRate!.id,
          useNoldCredit: orderData.useNoldCredit,
          authenticate: orderData.authenticate,
          discountCodes: orderData.discountCodes,
          isExpressCheckout: true,
          userGeolocation: userGeolocation || 'GB',
        });

        const { error: submitError } = await elements.submit();
        if (submitError) {
          logger.exception(submitError);
          return event.paymentFailed();
        }

        const { error, confirmationToken } = await stripe.createConfirmationToken({ elements });
        if (error) {
          toast.error('Invalid payment details. Please try again.');
          logger.exception(error);
          return event.paymentFailed();
        }

        const paymentIntent = await post({
          path: '/payment-intents',
          body: {
            transactionId: response.id.uuid || response.id,
            confirmationToken: confirmationToken.id,
          },
        });

        await confirmPayment({
          transactionId: response.id.uuid || response.id,
          paymentIntentId: paymentIntent.id,
        });

        redirectToOrdersPage();
      }}
    />
  );
};

export default ExpressCheckout;
