import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import {
  LayoutSingleColumn,
  MobileBottomNavigation,
  NamedRedirect,
  Page,
  TabsLayout,
} from '../../components';
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import BankAccount from './BankAccount/BankAccount';
import EditProfile from './EditProfile/EditProfile';
import ProfileAddressesForm from './ProfileAddressesForm/ProfileAddressesForm';
import { useCurrentUser } from 'hooks/selectors/useCurrentUser';
import { useIsScrollingDisabled } from 'hooks/selectors/useIsScrollingDisabled';

export const SettingsPageTab = {
  EditProfile: 'edit-profile',
  Addresses: 'addresses',
  BankAccount: 'bank-account',
} as const;
// TODO: Zod validate
type SettingsPageTab = (typeof SettingsPageTab)[keyof typeof SettingsPageTab];

export const ProfileSettingsPage = () => {
  const intl = useIntl();
  const currentUser = useCurrentUser();
  const userDateOfBirth = Boolean(currentUser.attributes.profile.privateData?.dateOfBirth);
  const title = intl.formatMessage({ id: 'ProfileSettingsPage.title' });
  const scrollingDisabled = useIsScrollingDisabled();
  const { tab } = useParams<{ tab: SettingsPageTab }>();

  if (!tab) {
    return <NamedRedirect name="SettingsPage" params={{ tab: SettingsPageTab.EditProfile }} />;
  }

  return (
    <Page title={title} scrollingDisabled={scrollingDisabled}>
      <LayoutSingleColumn
        topbar={<TopbarContainer currentPage="SettingsPage" />}
        footer={<MobileBottomNavigation />}
      >
        <TabsLayout<SettingsPageTab>
          title="Settings"
          tab={tab}
          defaultTab={SettingsPageTab.EditProfile}
          tabs={[
            {
              title: 'Edit Profile',
              content: <EditProfile />,
              linkProps: { name: 'SettingsPage', params: { tab: SettingsPageTab.EditProfile } },
              value: SettingsPageTab.EditProfile,
            },
            {
              title: 'Addresses',
              content: <ProfileAddressesForm />,
              linkProps: { name: 'SettingsPage', params: { tab: SettingsPageTab.Addresses } },
              value: SettingsPageTab.Addresses,
            },
            ...(userDateOfBirth
              ? [
                  {
                    title: 'Bank Account',
                    content: <BankAccount />,
                    linkProps: {
                      name: 'SettingsPage' as const,
                      params: { tab: SettingsPageTab.BankAccount },
                    },
                    value: SettingsPageTab.BankAccount,
                  },
                ]
              : []),
          ]}
        />
      </LayoutSingleColumn>
    </Page>
  );
};

export default ProfileSettingsPage;
