import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Field, Form as FinalForm, useForm } from 'react-final-form';
import { compose } from 'redux';

import {
  Button,
  FieldSelect,
  FieldTextInput,
  Form,
  PrimaryButton,
} from '../../../../../components';
import InternationalPhoneNumberInput from '../../../../../components/InternationalPhoneNumberInput/InternationalPhoneNumberInput';
import { useGetAddressSuggestionDetails } from '../../../../../hooks/api/addresses';
import { FormattedMessage, injectIntl, intlShape, useIntl } from '../../../../../util/reactIntl';
import * as validators from '../../../../../util/validators';
import AddressCombobox from '../AddressCombobox/AddressCombobox';
import css from './AddressForm.module.css';
import { Address } from 'hooks/api/addresses/useGetAddressSuggestions';
import { useCountryCodes } from 'hooks/useCountryCodes';

const FieldHidden = props => {
  const { name } = props;
  return (
    <Field id={name} name={name} type="hidden">
      {fieldRenderProps => <input {...fieldRenderProps?.input} />}
    </Field>
  );
};

const AddressFormComponent = props => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const {
        // @ts-expect-error TS(2339) FIXME: Property 'className' does not exist on type 'FormR... Remove this comment to see the full error message
        className,
        // @ts-expect-error TS(2339) FIXME: Property 'rootClassName' does not exist on type 'F... Remove this comment to see the full error message
        rootClassName,
        handleSubmit,
        // @ts-expect-error TS(2339) FIXME: Property 'onCloseModal' does not exist on type 'Fo... Remove this comment to see the full error message
        onCloseModal,
        // @ts-expect-error TS(2339) FIXME: Property 'isSubmitting' does not exist on type 'Fo... Remove this comment to see the full error message
        isSubmitting,
        // @ts-expect-error TS(2339) FIXME: Property 'isSubmitting' does not exist on type 'Fo... Remove this comment to see the full error message
        showEuOptions = false,
        form,
      } = fieldRenderProps;

      const classes = classNames(rootClassName || css.root, className);

      return (
        <Form
          // @ts-expect-error TS(2322) FIXME: Type '{ children: Element; className: string; onSu... Remove this comment to see the full error message
          className={classes}
          onSubmit={handleSubmit}
          onKeyDown={e => {
            if (e.key === 'Enter') {
              e.preventDefault();
              form.submit();
            }
          }}
        >
          <AddressFormFields showEuOptions={showEuOptions} />

          <div className={css.buttons}>
            <Button
              onClick={e => {
                e.preventDefault();
                onCloseModal();
              }}
            >
              <FormattedMessage id="ProfileAddressesForm.cancel" />
            </Button>

            <PrimaryButton type="submit" disabled={isSubmitting} inProgress={isSubmitting}>
              <FormattedMessage id="ProfileAddressesForm.save" />
            </PrimaryButton>
          </div>
        </Form>
      );
    }}
  />
);

AddressFormComponent.defaultProps = { className: null, rootClassName: null };

const { func, string } = PropTypes;

AddressFormComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
};

export type AddressFormData = {
  id?: string;
  fullName: string;
  country: string;
  phoneNumber: string;
  zip: string;
  street1: string;
  street2: string;
  city: string;
  validate: boolean;
};

export const emptyAddress = {
  fullName: '',
  country: '',
  phoneNumber: '',
  zip: '',
  street1: '',
  street2: '',
  city: '',
  validate: false,
};

export const AddressFormFields: React.FC<{ showEuOptions: boolean; disabled?: boolean }> = ({
  showEuOptions,
  disabled,
}) => {
  const intl = useIntl();
  const form = useForm();
  const values = form.getState().values;

  const getAddressDetails = useGetAddressSuggestionDetails();

  const handleAddressClick = async (address: Address) => {
    const result = await getAddressDetails(address.Id);
    const addressData = result.Items[0];

    form.change('zip', addressData.PostalCode);
    form.change('street1', addressData.Line1);
    form.change('street2', addressData.Line2);
    form.change('city', addressData.City);
  };

  const countryOptions = useCountryCodes(showEuOptions).map(c => ({
    option: c.code,
    label: c.name,
  }));

  return (
    <>
      <FieldHidden name="id" />

      <FieldTextInput
        id="fullName"
        name="fullName"
        className={css.input}
        type="text"
        label={intl.formatMessage({ id: 'ProfileAddressesForm.fullName' })}
        placeholder={intl.formatMessage({
          id: 'ProfileAddressesForm.fullNamePlaceholder',
        })}
        validate={validators.required(
          intl.formatMessage({ id: 'ProfileAddressesForm.fullNameRequired' })
        )}
        disabled={disabled}
      />
      <FieldSelect
        id="country"
        name="country"
        defaultValue={countryOptions[0].option}
        className={css.input}
        label={intl.formatMessage({ id: 'ProfileAddressesForm.country' })}
        placeholder={intl.formatMessage({
          id: 'ProfileAddressesForm.countryPlaceholder',
        })}
        validate={validators.required(
          intl.formatMessage({ id: 'ProfileAddressesForm.countryRequired' })
        )}
        disabled={disabled}
      >
        <option value="" disabled>
          Select
        </option>
        {countryOptions.map(c => (
          <option key={c.option} value={c.option}>
            {c.label}
          </option>
        ))}
      </FieldSelect>

      <FieldTextInput
        id="city"
        name="city"
        type="text"
        className={css.input}
        label={intl.formatMessage({ id: 'ProfileAddressesForm.city' })}
        placeholder={intl.formatMessage({ id: 'ProfileAddressesForm.cityPlaceholder' })}
        validate={validators.required(
          intl.formatMessage({ id: 'ProfileAddressesForm.cityRequired' })
        )}
        disabled={disabled}
      />

      <FieldTextInput
        className={css.input}
        id="zip"
        name="zip"
        type="text"
        label={intl.formatMessage({ id: 'ProfileAddressesForm.zip' })}
        placeholder={intl.formatMessage({ id: 'ProfileAddressesForm.zipPlaceholder' })}
        validate={validators.required(
          intl.formatMessage({ id: 'ProfileAddressesForm.zipRequired' })
        )}
        disabled={disabled}
      />

      <AddressCombobox
        searchData={[values.zip, values.city]}
        country={values.country}
        onAddressClick={handleAddressClick}
        value={values.street1}
        onChange={value => form.change('street1', value)}
        disabled={disabled}
      />

      <FieldTextInput
        className={css.input}
        id="street2"
        name="street2"
        type="text"
        label={intl.formatMessage({ id: 'ProfileAddressesForm.street2' })}
        placeholder={intl.formatMessage({ id: 'ProfileAddressesForm.street2Placeholder' })}
        disabled={disabled}
      />

      <InternationalPhoneNumberInput
        className={css.input}
        id="phoneNumber"
        name="phoneNumber"
        type="text"
        defaultCountry="GB"
        withCountryCallingCode
        countryCallingCodeEditable={false}
        international
        label={intl.formatMessage({ id: 'ProfileAddressesForm.phoneNumber' })}
        validate={validators.required(
          intl.formatMessage({ id: 'ProfileAddressesForm.phoneNumberRequired' })
        )}
        disabled={disabled}
      />

      {/* <div className={css.validateAddressContainer}>
        <Checkbox
          name="validate"
          label={intl.formatMessage({ id: 'ProfileAddressesForm.validateAddress' })}
          checked={values.validate}
          onCheckedChange={handleValidateAddress}
        />

        <Tooltip.Provider>
          <Tooltip.Root>
            <Tooltip.Trigger asChild>
              <button
                type="button"
                aria-label="Address validation info"
                className={css.tooltipIcon}
                onClick={toggleModal}
              >
                ?
              </button>
            </Tooltip.Trigger>
            <Tooltip.Content side="top" align="center" className={css.tooltipContent}>
              {tooltipContent}
              <Tooltip.Arrow className={css.tooltipArrow} />
            </Tooltip.Content>
          </Tooltip.Root>
        </Tooltip.Provider>

        <Modal
          open={showModal}
          onOpenChange={setShowModal}
          title={intl.formatMessage({ id: 'ProfileAddressesForm.validateAddress' })}
        >
          {tooltipContent}
        </Modal>
      </div> */}

      <FieldHidden name="street1" />
    </>
  );
};

const AddressForm = compose(injectIntl)(AddressFormComponent);
AddressForm.displayName = 'AddressForm';

export default AddressForm;
