import classNames from 'classnames';
import isEqual from 'lodash/isEqual';
import { bool, string } from 'prop-types';
import { useState } from 'react';
import { Field, Form as FinalForm } from 'react-final-form';
import toast from 'react-hot-toast';

import {
  Avatar,
  Button,
  FieldTextInput,
  FieldUsername,
  Form,
  IconSpinner,
  ImageFromFile,
  PrimaryButton,
} from '../../../../components';
import { isUploadImageOverLimitError } from '../../../../util/errors';
import { FormattedMessage, useIntl } from '../../../../util/reactIntl';
import { propTypes } from '../../../../util/types';
import * as validators from '../../../../util/validators';
import { useUploadImage } from '../EditProfile.duck';
import css from './ProfileSettingsForm.module.css';
import { useResetPassword } from 'hooks/api/users/useResetPassword';
import { useCurrentUser } from 'hooks/selectors/useCurrentUser';

const ACCEPT_IMAGES = 'image/*';

const ProfileSettingsForm = ({
  rootClassName,
  className,
  updateInProgress,
  updateProfileError,
  updateProfileReady,
  isSuccess,
  ...props
}) => {
  const [submittedValues, setSubmittedValues] = useState({});
  const intl = useIntl();

  // TODO: Add image upload delay

  const currentUser = useCurrentUser();
  const { mutate: resetPassword } = useResetPassword();
  const { mutateAsync: uploadImage, isLoading: fileUploadInProgress } = useUploadImage();

  return (
    // @ts-expect-error TS(2741) FIXME: Property 'onSubmit' is missing in type '{ render: ... Remove this comment to see the full error message
    <FinalForm
      {...props}
      render={fieldRenderProps => {
        const {
          // @ts-expect-error TS(2339) FIXME: Property 'className' does not exist on type 'FormR... Remove this comment to see the full error message
          className,
          handleSubmit,
          invalid,
          pristine,
          // @ts-expect-error TS(2339) FIXME: Property 'rootClassName' does not exist on type 'F... Remove this comment to see the full error message
          rootClassName,
          // @ts-expect-error TS(2339) FIXME: Property 'updateInProgress' does not exist on type... Remove this comment to see the full error message
          updateInProgress,
          // @ts-expect-error TS(2339) FIXME: Property 'uploadImageError' does not exist on type... Remove this comment to see the full error message
          uploadImageError,
          // @ts-expect-error TS(2339) FIXME: Property 'uploadInProgress' does not exist on type... Remove this comment to see the full error message
          uploadInProgress,
          form,
          values,
        } = fieldRenderProps;

        const handlePasswordResetClick = () => {
          resetPassword(currentUser.attributes.email, {
            onSuccess: () => {
              toast.success(
                `A password recovery email has been sent to ${currentUser.attributes.email}`
              );
            },
            onError: error => {
              toast.error('There was an error sending you a password recovery email');
            },
          });
        };

        const classes = classNames(rootClassName || css.root, className);
        const submitInProgress = updateInProgress;
        const submittedOnce = Object.keys(submittedValues).length > 0;
        const pristineSinceLastSubmit = submittedOnce && isEqual(values, submittedValues);
        const submitDisabled =
          invalid || pristine || pristineSinceLastSubmit || uploadInProgress || submitInProgress;

        const uploadingOverlay = uploadInProgress ? (
          <div className={css.uploadingImageOverlay}>
            <IconSpinner />
          </div>
        ) : null;

        const hasUploadError = !!uploadImageError && !uploadInProgress;
        const errorClasses = classNames({ [css.avatarUploadError]: hasUploadError });

        const profileImage = values.profileImage;
        const transientUser = { ...currentUser, profileImage };
        const isFile = Boolean(profileImage?.file);
        const avatarClasses = classNames(css.avatar, errorClasses);

        const imageFromFile = isFile ? (
          <ImageFromFile
            id={profileImage.id}
            className={avatarClasses}
            rootClassName={css.avatar}
            aspectWidth={1}
            aspectHeight={1}
            file={profileImage.file}
          >
            {uploadingOverlay}
          </ImageFromFile>
        ) : null;

        const avatarComponent = !isFile ? (
          <Avatar
            className={avatarClasses}
            renderSizes="(max-width: 767px) 96px, 240px"
            user={transientUser}
            disableProfileLink
          />
        ) : null;

        const chooseAvatarLabel =
          profileImage?.id || fileUploadInProgress ? (
            <div className={css.avatarContainer}>
              {imageFromFile}
              {avatarComponent}
              <div className={css.changeAvatar}>
                <FormattedMessage id="ProfileSettingsForm.changeAvatar" />
              </div>
            </div>
          ) : (
            <div className={css.avatarPlaceholder}>+ Add Photo</div>
          );

        return (
          <Form
            // @ts-expect-error TS(2322) FIXME: Type '{ children: (Element | null)[]; className: s... Remove this comment to see the full error message
            className={classes}
            onSubmit={e => {
              setSubmittedValues(values);
              handleSubmit(e);
            }}
          >
            <Field
              accept={ACCEPT_IMAGES}
              id="profileImage"
              name="profileImage"
              label={chooseAvatarLabel}
              type="file"
              form={null}
              uploadImageError={uploadImageError}
              disabled={uploadInProgress}
            >
              {fieldProps => {
                const { accept, id, input, label, disabled, uploadImageError } = fieldProps;
                const { name, type } = input;
                const onChange = e => {
                  const file = e.target.files[0];

                  if (file != null) {
                    const tempId = `${file.name}_${Date.now()}`;
                    const newImage = { id: tempId, file };

                    form.change('profileImage', newImage);
                    form.blur('profileImage');

                    uploadImage(newImage).then(response => {
                      form.change('profileImage', response.data.data);
                      form.blur('profileImage');
                    });
                  } else {
                    form.change('profileImage', null);
                    form.blur('profileImage');
                  }
                };

                let error = null;

                if (isUploadImageOverLimitError(uploadImageError)) {
                  // @ts-expect-error TS(2322) FIXME: Type 'Element' is not assignable to type 'null'.
                  error = (
                    <div className={css.error}>
                      <FormattedMessage id="ProfileSettingsForm.imageUploadFailedFileTooLarge" />
                    </div>
                  );
                } else if (uploadImageError) {
                  // @ts-expect-error TS(2322) FIXME: Type 'Element' is not assignable to type 'null'.
                  error = (
                    <div className={css.error}>
                      <FormattedMessage id="ProfileSettingsForm.imageUploadFailed" />
                    </div>
                  );
                }

                return (
                  <div>
                    <label className={css.label} htmlFor={id}>
                      {label}
                    </label>
                    <input
                      accept={accept}
                      id={id}
                      name={name}
                      style={{ display: 'none' }}
                      disabled={disabled}
                      onChange={onChange}
                      type={type}
                    />
                    {error}
                  </div>
                );
              }}
            </Field>
            <h3 className={css.sectionTitle}>
              <FormattedMessage id="ProfileSettingsForm.accountDetails" />
            </h3>
            <FieldTextInput
              type="text"
              id="firstName"
              name="firstName"
              label={intl.formatMessage({ id: 'ProfileSettingsForm.firstNameLabel' })}
              placeholder={intl.formatMessage({
                id: 'ProfileSettingsForm.firstNamePlaceholder',
              })}
              validate={validators.composeValidators(
                validators.required(
                  intl.formatMessage({ id: 'ProfileSettingsForm.firstNameRequired' })
                ),
                validators.lettersOnly(
                  intl.formatMessage({ id: 'ProfileSettingsForm.firstNameInvalid' })
                )
              )}
            />
            <FieldTextInput
              type="text"
              id="lastName"
              name="lastName"
              label={intl.formatMessage({
                id: 'ProfileSettingsForm.lastNameLabel',
              })}
              placeholder={intl.formatMessage({
                id: 'ProfileSettingsForm.lastNamePlaceholder',
              })}
              validate={validators.composeValidators(
                validators.required(
                  intl.formatMessage({ id: 'ProfileSettingsForm.lastNameRequired' })
                ),
                validators.lettersOnly(
                  intl.formatMessage({ id: 'ProfileSettingsForm.lastNameInvalid' })
                )
              )}
            />
            <FieldUsername />
            <FieldTextInput
              type="textarea"
              id="bio"
              name="bio"
              label={intl.formatMessage({ id: 'ProfileSettingsForm.bioLabel' })}
              placeholder={intl.formatMessage({ id: 'ProfileSettingsForm.bioPlaceholder' })}
            />
            <PrimaryButton
              type="submit"
              inProgress={submitInProgress}
              disabled={submitDisabled}
              ready={isSuccess}
            >
              <FormattedMessage id="ProfileSettingsForm.saveChanges" />
            </PrimaryButton>
            <Button
              type="button" // Specify this is a button to avoid submitting forms if used inside one
              onClick={handlePasswordResetClick}
            >
              <FormattedMessage id="ProfileSettingsForm.resetPassword" />
            </Button>
          </Form>
        );
      }}
    />
  );
};

ProfileSettingsForm.defaultProps = {
  rootClassName: null,
  className: null,
  updateProfileError: null,
  updateProfileReady: false,
};

ProfileSettingsForm.propTypes = {
  rootClassName: string,
  className: string,
  updateInProgress: bool.isRequired,
  // @ts-expect-error TS(2339) FIXME: Property 'error' does not exist on type '{}'.
  updateProfileError: propTypes.error,
  updateProfileReady: bool,
};

export default ProfileSettingsForm;
