import classNames from 'classnames';
import { arrayOf, string } from 'prop-types';
import React, { useState } from 'react';

import { IconCheck, IconClock, IconClose } from '../../../assets/icons';
import noImagePlaceholder from '../../../assets/no-image-placeholder.png';
import AspectRatioWrapper from '../../../components/AspectRatioWrapper/AspectRatioWrapper';
import { TransactionFilter, useGetTransactions } from '../../../hooks/api/transactions';
import {
  isAwaitingReviewByCustomer,
  transitions,
} from '../../../transactions/transactionProcessPurchase';
import { FormattedMessage, useIntl } from '../../../util/reactIntl';
import { propTypes } from '../../../util/types';
import commonCss from '../ProfilePageCommon.module.css';
import StarRating from '../StarRating/StarRating';
import css from './MyOrders.module.css';
import { NamedLink, PrimaryButton, ReviewModal } from 'components';
import { useGeolocation } from 'hooks/useGeolocation';
import { isEUCountry } from 'translations/countryCodes';
import { formatMoney } from 'util/currency';

const Order: React.FC<{
  order: {
    image: string;
    title: string;
    price: number;
    orderID: string;
    trackOrderLink?: string;
    status: string;
    date: string;
    rating?: number;
    convertedTotal?: {
      _sdkType: string;
      amount: number;
      currency: string;
    };
  };
  userCountry: string;
}> = props => {
  const { order, userCountry } = props;

  const [reviewModal, setReviewModal] = useState({ open: false, initialRating: 0 });

  const aspectWidth = 3;
  const aspectHeight = 4;

  const priceToShow =
    isEUCountry(userCountry) && order.convertedTotal ? order.convertedTotal : order.price;

  const price = formatMoney(useIntl(), priceToShow);
  const imageSrc = order.image || noImagePlaceholder;

  return (
    <div className={css.order}>
      <AspectRatioWrapper
        width={aspectWidth}
        height={aspectHeight}
        className={css.detailsAspectWrapper}
      >
        <img className={css.imageWrapper} src={imageSrc} alt="product" />
      </AspectRatioWrapper>
      <div className={css.mainInfo}>
        <span className={css.title}>{order.title}</span>
        <span className={css.price}>{price}</span>
      </div>
      <div className={css.orderInfo}>
        {order.trackOrderLink && (
          <a className={css.trackOrderBtn} href={order.trackOrderLink}>
            <FormattedMessage id="ProfilePage.myOrders.trackOrder" />
          </a>
        )}
        <div className={css.wrapper}>
          {([transitions.PAY] as string[]).includes(order.status) && (
            <>
              <IconClock />
              <span className={css.pending}>
                <FormattedMessage id="ProfilePage.myOrders.pending" />
              </span>
            </>
          )}
          {order.trackOrderLink && (
            <>
              <IconCheck />
              <span className={css.accepted}>
                <FormattedMessage id="ProfilePage.myOrders.accepted" />
              </span>
            </>
          )}
          {([transitions.REJECT, transitions.AUTO_REJECT] as string[]).includes(order.status) && (
            <>
              <IconClose />
              <span className={css.declined}>
                <FormattedMessage id="ProfilePage.myOrders.declined" />
              </span>
            </>
          )}
        </div>
        {isAwaitingReviewByCustomer(order.status) && (
          <span style={{ display: 'flex', gap: 'var(--n-size-1)', alignItems: 'center' }}>
            Review item
            <StarRating
              rating={0}
              onClick={i => setReviewModal({ open: true, initialRating: i })}
            />
            <ReviewModal
              open={reviewModal.open}
              onClose={() => setReviewModal(v => ({ ...v, open: false }))}
              initialRating={reviewModal.initialRating}
              transactionId={order.orderID}
            />
          </span>
        )}
        {order.rating && <StarRating rating={order.rating} />}
        <span className={css.date}>
          {new Date(order.date).toLocaleDateString('en-GB', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          })}
        </span>
      </div>
    </div>
  );
};

const MyOrders = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  const { userGeolocation } = useGeolocation();
  const { data: transactionsData, isLoading: loadingTransactions } = useGetTransactions({
    filter: TransactionFilter.ORDERS,
  });
  const transactions = transactionsData?.pages?.map((page: any) => page.data)?.flat() || [];

  const skipTransitions = [
    transitions.INQUIRE,
    transitions.REQUEST_TO_BUY_AFTER_INQUIRY,
    transitions.REQUEST_TO_BUY,
    transitions.EDIT_LINE_ITEMS,
  ];

  const filteredTransactions = transactions.filter(
    t => !skipTransitions.includes(t.attributes.lastTransition)
  );

  if (!filteredTransactions.length && !loadingTransactions) {
    return (
      <div className={commonCss.sectionContainer}>
        {' '}
        <div className={commonCss.emptyStateWithButton}>
          <span>No orders yet</span>
          <NamedLink name="SearchPage">
            <PrimaryButton>Browse listings</PrimaryButton>
          </NamedLink>
        </div>
      </div>
    );
  }

  return (
    <div className={commonCss.sectionContainer}>
      <div className={css.profileMyOrdersWrapper}>
        <h2 className={css.myOrdersTitle}>
          <FormattedMessage id="ProfilePage.myOrders.titleDesktop" />
        </h2>
      </div>
      <div className={classes}>
        {filteredTransactions.length && !loadingTransactions ? (
          filteredTransactions
            .map(t => ({
              id: t.id.uuid,
              image: t.listing?.images?.[0]?.attributes.variants['square-small'].url,
              title: t.listing.attributes.title,
              price: t.attributes.payinTotal,
              orderID: t.id.uuid,
              status: t.attributes.lastTransition,
              date: String(t.attributes.lastTransitionedAt),
              trackOrderLink: t.attributes.protectedData?.shippingTrackingUrl,
              convertedTotal: t.attributes.protectedData?.convertedTotal,
            }))
            .map(r => {
              return <Order order={r} userCountry={userGeolocation} />;
            })
        ) : loadingTransactions ? (
          <p>Loading...</p>
        ) : (
          <p>No orders yet</p>
        )}
      </div>
    </div>
  );
};

MyOrders.defaultProps = {
  className: null,
  rootClassName: null,
  myOrders: [],
};

MyOrders.propTypes = {
  className: string,
  rootCalssName: string,
  // @ts-expect-error TS(2339) FIXME: Property 'order' does not exist on type '{}'.
  myOrders: arrayOf(propTypes.order),
};

export default MyOrders;
