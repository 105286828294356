import { useMutation } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import { authInfo } from '../../../ducks/auth.duck';
import { fetchCurrentUser } from '../../../ducks/user.duck';
import { useSdk } from 'util/sdkContext';

export const useLogin = () => {
  const sdk = useSdk();
  const dispatch = useDispatch();

  return useMutation({
    mutationFn: ({ email, password }: { email: string; password: string }) => {
      return sdk.login({ username: email, password }).then(response => {
        if (response.status === 200) {
          const tokenData = {
            privateData: {
              accessToken: response?.data?.access_token,
              refreshToken: response?.data?.refresh_token,
            },
          };
          sdk.currentUser.updateProfile(tokenData);
        }
      });
    },
    onSuccess: async () => {
      await dispatch(authInfo());
      await dispatch(fetchCurrentUser());
    },
    meta: { name: 'login' },
  });
};
