import { useInfiniteQuery } from '@tanstack/react-query';

import { GetListingsWithFiltersRequest } from 'containers/SearchPage/SearchPage.hooks';
import { FiltersQueryParams } from 'containers/SearchPage/SearchPage.hooks';
import { useGeolocation } from 'hooks/useGeolocation';
import { get } from 'util/httpsClient';

const fetchListings = async (
  params: Partial<GetListingsWithFiltersRequest>,
  pageParam = 1,
  userGeolocation?: string
) => {
  const queryParams = new URLSearchParams();

  Object.entries({ ...params, page: pageParam, country: userGeolocation }).forEach(
    ([key, value]) => {
      if (value !== undefined) {
        if (Array.isArray(value)) {
          value.forEach(v => queryParams.append(key, v.toString()));
        } else if (typeof value === 'object') {
          queryParams.append(key, JSON.stringify(value));
        } else {
          queryParams.append(key, value.toString());
        }
      }
    }
  );

  const queryString = queryParams.toString();
  const path = `/listings${queryString ? `?${queryString}` : ''}` as const;

  const response = await get({
    path,
  });

  return {
    listings: response.listings,
    currentPage: response.meta.page,
    totalPages: response.meta.totalPages,
  };
};

export const useGetListings = (params: Partial<GetListingsWithFiltersRequest>) => {
  const { userGeolocation } = useGeolocation();

  return useInfiniteQuery({
    queryKey: ['listings', params],
    queryFn: ({ pageParam = 1 }) => fetchListings(params, pageParam, userGeolocation),
    getNextPageParam: lastPage => {
      if (lastPage.currentPage < lastPage.totalPages) {
        return lastPage.currentPage + 1;
      }
      return undefined;
    },
    meta: {
      errorMessage: 'Failed to fetch listings',
    },
  });
};

export const getListingsQueryKey = (searchParams: GetListingsSearchParams) =>
  ['listings', searchParams] as const;

export const getListingsQueryFn = (searchParams: GetListingsSearchParams & { page?: number }) => {
  return () => fetchListings(searchParams, searchParams.page);
};

export const GetListingSortByOption = {
  hottest: 'Hottest',
  newest: 'Newest',
  oldest: 'Oldest',
  'low-to-high': 'Price: Low to High',
  'high-to-low': 'Price: High to Low',
} as const;

export const GetListingSortByOptions = Object.entries(GetListingSortByOption).map(
  ([value, label]) => ({
    value,
    label,
  })
);

type GetListingsSearchParams = {
  category?: string;
  sortBy?: string;
} & Partial<FiltersQueryParams>;
