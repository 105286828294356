import { useQuery } from '@tanstack/react-query';

import { get } from '../../../util/httpsClient';
import * as log from '../../../util/log';
import { useCurrentUser } from '../../selectors/useCurrentUser';
import { useGeolocation } from 'hooks/useGeolocation';
import { isEUCountry } from 'translations/countryCodes';

export function useGetUserNoldCredit() {
  const currentUser = useCurrentUser();
  const { userGeolocation } = useGeolocation();
  const hasNoldCredit = currentUser?.attributes?.profile?.metadata?.noldCredit;

  return useQuery({
    queryKey: ['stripeAccount', hasNoldCredit],
    queryFn: async () => {
      try {
        if (!hasNoldCredit) {
          const currency = isEUCountry(userGeolocation) ? 'EUR' : 'GBP';
          return { amount: 0, currency };
        }
        return await get({ path: `/users/credit?userGeolocation=${userGeolocation}` });
      } catch (error: any) {
        log.error(error, 'failed-to-fetch-user-nold-credit', currentUser);
        throw error;
      }
    },
  });
}
