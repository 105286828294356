import React from 'react';
import { Modal } from '../../../../../components';
import { FormattedMessage, useIntl } from '../../../../../util/reactIntl';

type AssociatedListing = {
  id: string;
  title: string;
};

type Props = {
  open: boolean;
  onClose: () => void;
  listings: AssociatedListing[];
};

const AssociatedListingsModal: React.FC<Props> = ({ open, onClose, listings }) => {
  const intl = useIntl();
  const limitedListings = listings.slice(0, 10);
  const remainingListings = listings.slice(10);

  return (
    <Modal
      open={open}
      onOpenChange={onClose}
      title={intl.formatMessage({
        id: 'AssociatedListingsModal.title',
      })}
      closeOnOutsideClick={true}
    >
      <div className="relative w-full p-4 md:w-auto md:min-w-[30vw] md:bg-white">
        <p className="mb-4 text-1">
          {intl.formatMessage({ id: 'AssociatedListingsModal.message' })}
        </p>
        <ul className="mb-6 space-y-2">
          <b className="text-1">Active listings:</b>
          {limitedListings.map(listing => (
            <li key={listing.id} className="py-2 border-b border-gray-200 last:border-b-0 text-1">
              {listing.title}
            </li>
          ))}
          {remainingListings.length > 0 && (
            <li className="py-2 border-b border-gray-200 last:border-b-0 text-1">
              <b>
                <FormattedMessage
                  id="AssociatedListingsModal.moreListings"
                  values={{ count: remainingListings.length }}
                />
              </b>
            </li>
          )}
        </ul>
      </div>
    </Modal>
  );
};

export default AssociatedListingsModal;
